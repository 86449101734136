import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from '../../../../stitches.config'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn'
import { faXTwitter } from '@fortawesome/free-brands-svg-icons/faXTwitter'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

export interface SocialProps {
    facebookUrl?: string
    linkedinUrl?: string
    twitterUrl?: string
    instagramUrl?: string
    youtubeUrl?: string
    iconColor?: 'white' | 'primary' | 'secondary' | 'secondaryDark'
    size?: 'sm' | 'md' | 'lg'
    justifyContent?: 'flex-start' | 'center' | 'flex-end'
    gap?: 'default' | 'wide'
}

interface SocialButtonProps {
    icon: IconDefinition
    url: string
    name: string
}

const IconsWrapper = styled('div', {
    display: 'flex',
    mb: '.5rem',
    '& svg': {
        br: '50%',
    },
    variants: {
        iconColor: {
            white: {
                color: '$white',
                '& svg': {
                    border: '1px solid $white',
                },
            },
            primary: {
                color: '$primary',
                '& svg': {
                    border: '1px solid $primary',
                },
            },
            secondary: {
                color: '$secondary',
                '& svg': {
                    border: '1px solid $secondary',
                },
            },
            secondaryDark: {
                color: '$secondaryDark',
                '& svg': {
                    border: '1px solid $secondaryDark',
                },
            },
        },
        size: {
            sm: {
                '& svg': {
                    minWidth: '1rem',
                    fontSize: '.8rem',
                    p: '.3rem',
                },
            },
            md: {
                '& svg': {
                    minWidth: '1.35rem',
                    fontSize: '1rem',
                    p: '.4rem',
                },
            },
            lg: {
                '& svg': {
                    minWidth: '1.75rem',
                    fontSize: '1.25rem',
                    p: '.5rem',
                },
            },
        },
        justifyContent: {
            'flex-start': {
                jc: 'flex-start',
            },
            center: {
                jc: 'center',
            },
            'flex-end': {
                jc: 'flex-end',
            },
        },
        gap: {
            default: {
                gap: '.35rem',
            },
            wide: {
                gap: '.6rem',
            },
        },
    },
    defaultVariants: {
        iconColor: 'secondaryDark',
        size: 'sm',
        justifyContent: 'flex-start',
        gap: 'default',
    },
})

const SocialIcon = ({ icon, url, name }: SocialButtonProps) => {
    return (
        <a
            href={url}
            rel="noreferrer noopener"
            target="_blank"
            aria-label={`${name} button`}
        >
            <FontAwesomeIcon icon={icon} />
        </a>
    )
}

export const SocialShareIcons = ({
    facebookUrl,
    linkedinUrl,
    twitterUrl,
    instagramUrl,
    youtubeUrl,
    iconColor,
    size,
    justifyContent,
    gap,
}: SocialProps) => {
    return (
        <IconsWrapper
            iconColor={iconColor}
            size={size}
            justifyContent={justifyContent}
            gap={gap}
            className={'social-icons'}
        >
            {facebookUrl && (
                <SocialIcon
                    icon={faFacebookF}
                    url={facebookUrl}
                    name={'Facebook'}
                />
            )}
            {linkedinUrl && (
                <SocialIcon
                    icon={faLinkedinIn}
                    url={linkedinUrl}
                    name={'LinkedIn'}
                />
            )}
            {twitterUrl && (
                <SocialIcon
                    icon={faXTwitter}
                    url={twitterUrl}
                    name={'Twitter'}
                />
            )}
            {instagramUrl && (
                <SocialIcon
                    icon={faInstagram}
                    url={instagramUrl}
                    name={'Instagram'}
                />
            )}
            {youtubeUrl && (
                <SocialIcon
                    icon={faYoutube}
                    url={youtubeUrl}
                    name={'Youtube'}
                />
            )}
        </IconsWrapper>
    )
}
