import axios from 'axios'
import { xhrApiClientMock } from './xhrApiClientMock'
import getConfig from 'next/config'
import { firebaseAuth } from '@/config/firebaseClientConfig'

const xhrApiClientLive = (() => {
    const getAuthToken = async (): Promise<string | null> => {
        let bearerToken: string | undefined
        try {
            bearerToken = await firebaseAuth.currentUser?.getIdToken()
        } catch (err) {
            console.log('getAuthToken', err)
        }

        return bearerToken ?? null
    }

    const instance = axios.create({
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
    })

    instance.interceptors.request.use(async (config) => {
        const authToken: string | null = await getAuthToken()

        if (authToken && config?.headers) {
            config.headers.Authorization = `Bearer ${authToken}`
        }

        return config
    })

    return instance
})()

const { publicRuntimeConfig } = getConfig()

// To enable the mock client, add this to .env.local: AXIOS_MOCK_XHR=1
const useMock =
    process.env.NODE_ENV === 'test' ||
    Boolean(parseInt(String(publicRuntimeConfig.axiosMockXhr)))

useMock && console.log('Using XHR mock Axios instance.')

export const xhrApiClient = useMock ? xhrApiClientMock : xhrApiClientLive
