import { styled } from '@/src/stitches.config'
import { gradientAnimation } from '@/src/common/animations'
import LinkButton from '@/src/common/components/marketing/buttons/LinkButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const ColorfulContainer = styled('div', {
    width: '100vw',
    backgroundSize: '200% 110%',
    animation: `${gradientAnimation} 8s ease infinite`,
    textAlign: 'center',
    color: '$white',
    py: 5,
    variants: {
        gradientStyle: {
            default: {
                background: `-webkit-linear-gradient(87deg, $primary 18.47%, $secondary 80%)`,
            },
            green: {
                background: `-webkit-linear-gradient(87deg, #206a05 18.47%, #2b8704 80%)`,
            },
            red: {
                background: `-webkit-linear-gradient(87deg, #9c0500 18.47%, #c40901 80%)`,
            },
        },
    },
    defaultVariants: {
        gradientStyle: 'default',
    },
})

export const ButtonsContainer = styled('div', {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    ai: 'center',
    jc: 'center',
    px: '5px',
})

export const BannersWrapper = styled('div', {
    position: 'relative',
    display: 'flex',
    overflow: 'hidden',
    fontSize: '.9rem',
    minHeight: 45,
    maxWidth: '100%',
    '@sm': {
        width: 900,
        fontSize: '1rem',
    },
    '.active': {
        transform: 'translateX(0)',
    },
})

export const BannerContent = styled('div', {
    display: 'flex',
    jc: 'center',
    ai: 'center',
    flex: '0 0 100%',
    transition: 'transform 0.5s ease-in-out',
    gap: 20,
    '& p': {
        color: '$white',
        margin: 0,
    },
})

export const TextAndIconWrapper = styled('div', {
    display: 'flex',
    ai: 'center',
    jc: 'center',
    gap: 5,
    color: '$white !important',
    cursor: 'pointer',
    '&:hover': {
        textDecoration: 'underline',
    },
})

export const BannerTextSpan = styled('span', {
    '& p': {
        width: '100%',
        margin: 'auto',
    },
})

export const BookEnd = styled('svg', {})

export const BannerButton = styled(LinkButton, {
    display: 'none',
    backgroundColor: 'unset !important',
    textDecoration: 'underline',
    border: 'unset !important',
    color: '$white !important',
    padding: '0px !important',
    '@sm': {
        display: 'inline-block',
    },
})

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon, {
    width: 25,
    cursor: 'pointer',
    zIndex: 2,
})
