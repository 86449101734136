import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import Link from 'next/link'
import { styled, theme } from '@/src/stitches.config'
import NavItemInterface from './NavItemInterface'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import NavigationMenuDropdownItem from './NavigationMenuDropdownItem'
import { Fragment } from 'react'

const ChevronIcon = styled(FontAwesomeIcon, {
    color: '$primary',
    width: '12px',
    height: '12px',
    length: 0,
    marginLeft: '.5rem',
    '[data-state=open] &': {
        color: '$primary',
    },
    '@media (prefers-reduced-motion: no-preference)': {
        transition: 'transform 250ms ease',
    },
})

const ContainerDiv = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    pt: '30px',
    px: '15px',
    pb: '15px',
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: '0 0 3px 3px',
    background: '$white',
    maxWidth: '100%',
    minWidth: '470px',
    navDropdownShadow: '',
    '&.lastMenu': {
        left: 'unset',
        right: 0,
        minWidth: '300px',
    },
})

const LabelMenu = styled(NavigationMenu.Trigger, {
    fontFamily: theme.fonts.default,
    borderRadius: '0',
    backgroundColor: 'transparent',
    width: '100%',
    padding: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: 'none',
    position: 'relative',
    color: '$darkest',
    fontWeight: 500,
    fontSize: '1em',
    whiteSpace: 'nowrap',
    '&:hover': {
        cursor: 'pointer',
    },
    '&[data-state="open"]': {
        textDecoration: 'underline',
        textDecorationColor: '$primary',
        textDecorationThickness: '2px',
        textUnderlineOffset: '5px',
        zIndex: 0,
        background: '$white',
    },
})

const NavigationMenuList = styled(NavigationMenu.List, {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    listStyle: 'none',
    justifyContent: 'space-between',
    padding: 0,
    width: '100%',
    maxWidth: '1440px',
    margin: '8px auto',
})

const NavigationMenuItem = styled(NavigationMenu.Item, {
    '&.hasChildren&:hover': {
        // This style shows the shadow under the menu trigger.
        navDropdownShadow: '',
    },
})

const NavigationMenuItemDivider = styled('li', {
    display: 'block',
    bc: '$mediumGray',
    width: '1px',
    my: '6px',
})

interface DesktopMenuProps {
    menuData: Array<NavItemInterface>
    isCorp?: Boolean | undefined
}

export const NavigationMenuWithHover: React.FC<DesktopMenuProps> = ({
    menuData,
    isCorp,
}) => {
    const menuCount = menuData.length
    const NavigationMenuRoot = styled(NavigationMenu.Root, {
        bc: isCorp ? '$white' : '$light',
        width: '100%',
        px: isCorp ? '0' : '4vw',
        display: 'flex',
        flexGrow: 1,
        '> div': {
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
        },
    })

    return (
        <NavigationMenuRoot>
            <NavigationMenuList>
                {menuData.map((navItem, index) => (
                    <Fragment key={index}>
                        <NavigationMenuItem
                            onPointerMove={(event) => event.preventDefault()}
                            onPointerLeave={(event) => event.preventDefault()}
                            className={navItem.children ? 'hasChildren' : ''}
                            data-testid={navItem.test_id ?? ''}
                        >
                            {navItem.url ? (
                                <Link passHref href={navItem.url} tabIndex={-1}>
                                    <LabelMenu>
                                        {navItem.label}{' '}
                                        {navItem.children && (
                                            <ChevronIcon icon={faChevronDown} />
                                        )}
                                    </LabelMenu>
                                </Link>
                            ) : (
                                <LabelMenu>
                                    {navItem.label}{' '}
                                    {navItem.children && (
                                        <ChevronIcon icon={faChevronDown} />
                                    )}
                                </LabelMenu>
                            )}

                            {navItem.children && (
                                <NavigationMenu.Content
                                    style={{
                                        position: 'relative',
                                        minWidth: 'max-content',
                                        zIndex: 2,
                                    }}
                                >
                                    <ContainerDiv
                                        className={
                                            index + 1 >= menuCount
                                                ? 'lastMenu'
                                                : ''
                                        }
                                        data-testid={'dropdown-content-wrapper'}
                                    >
                                        {navItem.children &&
                                            navItem.children.map(
                                                (menuItemData) => (
                                                    <NavigationMenuDropdownItem
                                                        key={menuItemData.label}
                                                        {...menuItemData}
                                                    />
                                                )
                                            )}
                                    </ContainerDiv>
                                </NavigationMenu.Content>
                            )}
                        </NavigationMenuItem>
                        {!isCorp && index + 1 < menuCount && (
                            <NavigationMenuItemDivider />
                        )}
                    </Fragment>
                ))}
            </NavigationMenuList>
        </NavigationMenuRoot>
    )
}

export default NavigationMenuWithHover
