import {
    PopoverContent,
    PopoverRoot,
    PopoverTrigger,
} from '@/src/common/components/elements/Buttons/Popover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo'
import { styled } from '@/src/stitches.config'

const StyledPopoverContent = styled(PopoverContent, {
    zIndex: 3,
    '& :first-child': {
        m: 0,
    },
})

interface IPopover {
    promoTerms: string
}

const BannerPopover = ({ promoTerms }: IPopover) => {
    return (
        <PopoverRoot>
            <PopoverTrigger
                id="info-button"
                ariaLabel="Promotion info"
                variant={'defaultHideOnSm'}
            >
                <FontAwesomeIcon icon={faInfo} />
            </PopoverTrigger>
            <StyledPopoverContent
                style={{
                    zIndex: 2,
                    fontSize: '.8rem',
                }}
            >
                <div
                    dangerouslySetInnerHTML={{
                        __html: promoTerms,
                    }}
                />
            </StyledPopoverContent>
        </PopoverRoot>
    )
}

export default BannerPopover
