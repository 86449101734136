import { ReactNode } from 'react'

export enum DisplayWhenTypes {
    LOGGED_IN = 'logged_in',
    LOGGED_OUT = 'logged_out',
    ALWAYS = 'always',
}

export interface IMobileNavBaseItem {
    label: string
    testId: string
    url?: string
    displayWhen?: DisplayWhenTypes
    gradientText?: string
    icon?: string
    subSection?: IMobileNavSubSection[]
    className?: string
    showChat?: boolean
}

export interface IMobileNavSubSection {
    children: IMobileNavSubSectionChild[]
    testId?: string
    label?: string
    formattedContent?: ReactNode
}

export interface IMobileNavSubSectionChild {
    label: string
    testId: string
    url?: string
    gradientText?: string
    clickAction?: () => Promise<void>
    icon?: string
    className?: string
    showChat?: boolean
}
