import { ICart } from '../../modules/cart/ICart'

/**
 * Removes the specified line item from the cart data. Remove deliveries
 * that don't contain any line items.
 * @param cart
 * @param lineItemUuid
 * @returns ICart
 */
export const removeLineItemFromCart = (
    cart: ICart,
    lineItemUuid: string
): ICart => {
    return {
        ...cart,
        cart_contents: {
            deliveries: [
                ...cart.cart_contents.deliveries
                    .map((delivery) => {
                        return {
                            ...delivery,
                            line_items: [
                                ...delivery.line_items.filter((lineItem) => {
                                    return (
                                        lineItem.line_item_uuid !== lineItemUuid
                                    )
                                }),
                            ],
                        }
                    })
                    .filter((delivery) => {
                        return delivery.line_items.length > 0
                    }),
            ],
        },
    }
}
