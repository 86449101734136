export const minCards = 1
export const minCardsBox = 5
export const minCardLoad = 10
export const minPGPlusCardLoad = 5
export const maxSingleCardLoad = 500

export const KYC_MAX_PURCHASE_AMOUNT = 10000
export const KYC_MAX_CARDS = 50
export const KYC_MAX_ORDERS = 50
export const MAX_PURCHASE_AMOUNT = 2500
export const MAX_CARDS = 20
export const MAX_ORDERS = 20

export const minMessageChars = 2
export const maxMessageChars = 150

export const BOX_URL = 'visa-gift-card-box'
export const PERSONALIZED_VISA_URL = 'personalized-visa-gift-card'

export const PHONE_CHECK_STORAGE_NAME = 'limen_tophi'
export const PHONE_CHECK_EXP_NAME = 'limen_tophi_xp'
