import {
    AlertText,
    AlertTextWrapper,
    AlertWrapper,
} from '@/src/common/components/elements/Alerts/StyledComponents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'
import { MAX_CARDS, MAX_PURCHASE_AMOUNT } from '@/src/modules/checkout/strings'

const PurchaseFlowMaxAlert = ({
    isLogin = false,
    style = {},
}: {
    isLogin?: boolean
    style?: object
}) => {
    const actionText: string = isLogin
        ? 'Please login or sign up for a PerfectGift account so we can accurately process your order.'
        : 'This order may require additional steps in the checkout process.'

    return (
        <AlertWrapper variant={'warning'} style={style}>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <AlertTextWrapper>
                <AlertText>
                    Attention! Your order has a gift quantity greater than{' '}
                    {MAX_CARDS} or an order value of more than $
                    {MAX_PURCHASE_AMOUNT}. {actionText}
                </AlertText>
            </AlertTextWrapper>
        </AlertWrapper>
    )
}

export default PurchaseFlowMaxAlert
