import { ICart } from '@/src/modules/cart/ICart'
import { ICardToCardProduct, IVisaProduct } from '@/src/common/interfaces/gtag'
import { MEDIUM_TYPE_CARD_TO_CARD } from '@/prisma/schemaConstants'
import {
    pushBrandedPGPlusProductToArray,
    pushUnbrandedPGPlusProductToArray,
    pushVisaProductToArray,
} from '@/src/common/utilities/formatAndPushGtagProducts'
import { CUSTOM_CARD_IMAGE } from '@/src/common/constants'
import { getLineItemsForCart } from '@/src/modules/cart/utilities'

export default function buildGoogleEcommerceItemsFromCart(cartData: ICart) {
    const productData: (IVisaProduct | ICardToCardProduct)[] = []
    const deliveries = cartData?.cart_contents?.deliveries
    if (!deliveries) {
        return productData
    }

    const lineItems = getLineItemsForCart(cartData)
    const { lineItemsInBoxes, lineItemsNotInBox } = lineItems

    // handle non-box items
    lineItemsNotInBox.forEach((item) => {
        const { denomination, line_item_type } = item
        const { product_medium, product_brand } = item.product.resolved_data
        const designTypeOrName = item?.custom_design?.url
            ? CUSTOM_CARD_IMAGE
            : item?.design?.resolved_data?.design_name ?? ''

        if (line_item_type === MEDIUM_TYPE_CARD_TO_CARD) {
            const brandName =
                item?.customization_data?.selected_gyp_brand?.resolved_data
                    ?.brand_name ?? product_brand
            const isPgPlus: boolean =
                item?.customization_data?.selected_gyp_brand?.resolved_data
                    ?.is_pg_plus ?? false

            if (isPgPlus) {
                pushUnbrandedPGPlusProductToArray({
                    array: productData,
                    brandName: brandName,
                    designTypeOrName: designTypeOrName,
                    denomination: denomination,
                })
                return
            }

            pushBrandedPGPlusProductToArray({
                array: productData,
                brandName: brandName,
                designTypeOrName: designTypeOrName,
                denomination: denomination,
                item: item,
            })
            return
        }

        pushVisaProductToArray({
            array: productData,
            brandName: product_brand,
            designTypeOrName: designTypeOrName,
            mediumType: product_medium?.medium_type,
            denomination: denomination,
        })
    })

    // handle items in boxes
    lineItemsInBoxes.forEach((item) => {
        const { items } = item
        const denomination = items[0].denomination
        const mediumType =
            items[0].product.resolved_data.product_medium.medium_type
        const productBrand = items[0].product.resolved_data.product_brand
        const designTypeOrName = items[0]?.custom_design?.url
            ? CUSTOM_CARD_IMAGE
            : items[0]?.design?.resolved_data?.design_name ?? ''
        const quantity = items.length

        pushVisaProductToArray({
            array: productData,
            brandName: productBrand,
            designTypeOrName: designTypeOrName,
            mediumType: mediumType,
            denomination: denomination,
            isBox: true,
            quantity: quantity,
        })
    })

    return productData
}
