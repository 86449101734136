import { styled } from '@/src/stitches.config'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'

export const NavigationMenuRoot = styled(NavigationMenu.Root, {
    display: 'flex',
    jc: 'center',
    ai: 'center',
    zIndex: 2,
})
export const NavigationMenuList = styled(NavigationMenu.List, {
    display: 'flex',
    jc: 'flex-end',
    background: '$white',
    padding: 4,
    listStyle: 'none',
    boxShadow: '0 2px 10px $lightest',
    margin: 0,
    position: 'relative',
})
export const NavigationMenuContent = styled(NavigationMenu.Content, {
    maxWidth: '100%',
    position: 'absolute',
    minWidth: 250,
    maxHeight: '75vh',
    animationDuration: '250ms',
    animationTimingFunction: 'ease',
    background: '$white',
    navDropdownShadow: '',
    px: '13%',
    py: 20,
    borderRadius: '0px 7px 7px 7px',
    overflowY: 'auto',
    '@sm': {
        width: 268,
    },
    variants: {
        direction: {
            left: {
                borderRadius: '7px 0px 7px 7px',
                left: '-134px',
            },
            right: {
                right: '5px',
                borderRadius: 0,
            },
        },
    },
})
export const NavigationMenuItem = styled(NavigationMenu.Item, {
    '&.hasChildren&:hover': {
        navDropdownShadow: '',
    },
})

export const NavigationSectionHeader = styled('div', {
    color: '$mediumGray',
    textTransform: 'uppercase',
    fontSize: '0.9em',
    fontWeight: 300,
    my: 15,
    '&:first-of-type': {
        mt: 'inherit',
    },
})
