import { GradientText } from '@/src/common/components/marketing/heros/StyledComponents'
import { styled } from '@/src/stitches.config'
import { BlueLine } from '@/src/modules/pg-plus/PGPlusStyledComponents'

const ContentWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 899px)': {
        fontSize: '.9rem',
        ml: '5%',
        mb: 5,
    },
})

const StyledText = styled('a', {
    fontWeight: 'bold',
    mb: 7,
    '@md': {
        mb: 13,
        px: 5,
    },
    variants: {
        variant: {
            contact: {
                my: 0,
                br: '$2',
                padding: '7px 0px',
                cursor: 'pointer',
                '&:hover': {
                    background: '$lightest',
                },
                '& span': {
                    mr: 5,
                },
                '@md': {
                    px: 5,
                },
            },
        },
    },
})

const StyledBlueLine = styled(BlueLine, {
    marginTop: 10,
    marginBottom: 15,
    '@md': {
        ml: 5,
    },
})

const ContactUsText = () => {
    return (
        <ContentWrapper>
            <StyledBlueLine />
            <StyledText>
                Need <GradientText>customer support?</GradientText>
            </StyledText>
            <StyledText
                variant={'contact'}
                href="tel:+18774484438"
                data-phone={'phone-number'}
                style={{ marginBottom: 3 }}
            >
                <GradientText>Call:</GradientText> (877) 448-4438
            </StyledText>
            <StyledText
                variant={'contact'}
                href="sms:+14129124341"
                data-phone={'phone-number'}
            >
                <GradientText>Text:</GradientText> (412) 912-4341
            </StyledText>
        </ContentWrapper>
    )
}

export default ContactUsText
