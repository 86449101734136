import { PGPlusContentWrapper } from '@/src/modules/pg-plus/PGPlusStyledComponents'
import { BasicOverlay } from '@/src/common/components/elements/StyledComponents'

type TOverlayProps = {
    isPgPlus: boolean
    value: string
    borderRadius?: string | number
    isWrapped?: boolean
}

const PGPlusCardOverlay = ({
    isPgPlus,
    value,
    borderRadius = 12,
    isWrapped = false,
}: TOverlayProps) => {
    if (!isPgPlus) {
        return <></>
    }

    const zIndex = isWrapped ? -3 : undefined

    return (
        <BasicOverlay style={{ borderRadius: borderRadius, zIndex: zIndex }}>
            <PGPlusContentWrapper>
                <p style={{ fontWeight: 'bold' }}>{value}</p>
            </PGPlusContentWrapper>
        </BasicOverlay>
    )
}

export default PGPlusCardOverlay
