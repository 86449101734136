import { styled } from '@/src/stitches.config'
import Image from 'next/image'
import Card from '@/src/common/components/marketing/cards/Card'

const Wrapper = styled('div', {
    width: '100%',
    position: 'relative',
    '.greeting-card-image': {
        width: '75%',
        height: 'auto',
        boxShadow: 'gray 0px 1px 10px -1px',
    },
    '.card-image': {
        position: 'absolute',
        bottom: 8,
        right: 0,
        border: '2px solid $white',
        maxWidth: '75% !important',
    },
})

interface IGreetingCardItem {
    greetingCardImage: string
    cardImage: string
    priority?: boolean
}

const GreetingCardGiftCard = ({
    greetingCardImage,
    cardImage,
    priority = false,
}: IGreetingCardItem) => {
    return (
        <Wrapper>
            <Image
                src={greetingCardImage}
                alt={'Greeting Card Image'}
                width={640}
                height={848}
                className={'greeting-card-image'}
                priority={priority}
            />
            <Card
                size={'sm'}
                image={cardImage}
                overlayType={'full'}
                isExtended={false}
                showLoader={!cardImage}
                priority={priority}
                showCustomFields={false}
                borderRadius={5}
            />
        </Wrapper>
    )
}

export default GreetingCardGiftCard
