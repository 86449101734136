import {
    ButtonWrapper,
    CloseButton,
    ImagesWrapper,
    NavigationWrapper,
} from '@/src/common/components/elements/Layout/Navigation/StyledComponents'
import { faClose } from '@fortawesome/free-solid-svg-icons/faClose'
import { consumerMobileNavMenuData } from '@/src/common/components/elements/Layout/Navigation/Mobile/consumerMobileNavMenuData'
import { corporateMobileNavMenuData } from '@/src/common/components/elements/Layout/Navigation/Mobile/corporateMobileNavMenuData'
import LinkButton from '@/src/common/components/marketing/buttons/LinkButton'
import { IMobileProps } from '@/src/common/components/elements/Layout/Navigation/Mobile/types'
import NavigationItem from '@/src/common/components/elements/Layout/Navigation/Mobile/NavigationItem'
import Image from 'next/image'
import { DisplayWhenTypes, IMobileNavBaseItem } from '../types'
import { useUser } from '@/src/modules/auth/context/AuthProvider'
import { createPartnerMobileNavMenuData } from '@/src/common/components/elements/Layout/Navigation/Mobile/createPartnerMobileNavMenuData'
import { PARTNER_BRAND_PAGE } from '@/src/modules/pg-plus/constants'

function determineItems(
    isCorporate: boolean,
    partner: string | undefined
): {
    mobileNavItems: IMobileNavBaseItem[]
    button1Text: string
    button1Url: string
    button2Text?: string
    button2Url?: string
} {
    if (partner) {
        const partnerMobileNavData = createPartnerMobileNavMenuData()
        return {
            mobileNavItems: partnerMobileNavData,
            button1Text: 'Send a gift',
            button1Url: PARTNER_BRAND_PAGE,
        }
    }

    if (isCorporate) {
        return {
            mobileNavItems: corporateMobileNavMenuData,
            button1Text: 'Small business order',
            button1Url: '/corporate/get-started',
            button2Text: 'For consumer',
            button2Url: '/',
        }
    }

    return {
        mobileNavItems: consumerMobileNavMenuData,
        button1Text: 'Send a gift',
        button1Url: '/visa-gift-cards',
        button2Text: 'For business',
        button2Url: '/corporate',
    }
}

const MobileNavigation = ({
    isCorporate,
    partner,
    closeMenu,
    menuIsClosing,
    setMenuIsClosing,
}: IMobileProps) => {
    const { user } = useUser()
    const { mobileNavItems, button1Text, button1Url, button2Text, button2Url } =
        determineItems(isCorporate, partner)

    return (
        <NavigationWrapper>
            <CloseButton
                icon={faClose}
                onClick={() => {
                    setMenuIsClosing(true)
                    closeMenu()
                }}
            />
            {mobileNavItems?.map((item) => {
                const showItem =
                    item.displayWhen === DisplayWhenTypes.ALWAYS ||
                    (user && item.displayWhen === DisplayWhenTypes.LOGGED_IN) ||
                    (!user &&
                        item.displayWhen === DisplayWhenTypes.LOGGED_OUT) ||
                    !item.displayWhen

                if (!showItem) {
                    return null
                }
                return (
                    <div key={item.testId}>
                        <NavigationItem
                            item={item}
                            menuIsClosing={menuIsClosing}
                            closeMenu={closeMenu}
                        />
                    </div>
                )
            })}
            <ButtonWrapper>
                <LinkButton
                    label={button1Text}
                    variant={'primary'}
                    url={button1Url}
                    textTransform={'uppercase'}
                    size={'sm'}
                    showArrow={false}
                />
                {button2Text && button2Url && (
                    <LinkButton
                        label={button2Text}
                        variant={'primaryOutline'}
                        url={button2Url}
                        textTransform={'uppercase'}
                        size={'sm'}
                        showArrow={false}
                    />
                )}
            </ButtonWrapper>
            <ImagesWrapper>
                <Image
                    src={'/images/visa_logo.png'}
                    height={63}
                    width={191}
                    alt={'Visa logo'}
                />
                <Image
                    src={'/images/mc_logo.png'}
                    height={63}
                    width={191}
                    alt={'Mastercard logo'}
                />
            </ImagesWrapper>
        </NavigationWrapper>
    )
}

export default MobileNavigation
