import { faBriefcase } from '@fortawesome/pro-light-svg-icons/faBriefcase'
import { faCirclePlus } from '@fortawesome/pro-light-svg-icons/faCirclePlus'
import { faRightToBracket } from '@fortawesome/pro-light-svg-icons/faRightToBracket'
import { faUserPlus } from '@fortawesome/pro-light-svg-icons/faUserPlus'
import { faStore } from '@fortawesome/pro-light-svg-icons/faStore'
import { faGift } from '@fortawesome/pro-light-svg-icons/faGift'
import { faHome } from '@fortawesome/pro-light-svg-icons/faHome'
import { faGear } from '@fortawesome/pro-light-svg-icons/faGear'
import { faPhone } from '@fortawesome/pro-light-svg-icons/faPhone'
import { faCommentDots } from '@fortawesome/pro-light-svg-icons/faCommentDots'
import { faComments } from '@fortawesome/pro-light-svg-icons/faComments'
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope'
import { faSquareQuestion } from '@fortawesome/pro-light-svg-icons/faSquareQuestion'
import { faCreditCard } from '@fortawesome/pro-light-svg-icons/faCreditCard'
import { faTruckFast } from '@fortawesome/pro-light-svg-icons/faTruckFast'
import { faReceipt } from '@fortawesome/pro-light-svg-icons/faReceipt'
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser'

function determineFAIcon(icon: string) {
    switch (icon) {
        case 'fa-briefcase':
            return faBriefcase
        case 'fa-circle-plus':
            return faCirclePlus
        case 'fa-right-to-bracket':
            return faRightToBracket
        case 'fa-user-plus':
            return faUserPlus
        case 'fa-store':
            return faStore
        case 'fa-gift':
            return faGift
        case 'fa-home':
            return faHome
        case 'fa-gear':
            return faGear
        case 'fa-phone':
            return faPhone
        case 'fa-comment-dots':
            return faCommentDots
        case 'fa-comments':
            return faComments
        case 'fa-envelope':
            return faEnvelope
        case 'fa-square-question':
            return faSquareQuestion
        case 'fa-credit-card':
            return faCreditCard
        case 'fa-truck-fast':
            return faTruckFast
        case 'fa-receipt':
            return faReceipt
        case 'fa-user':
            return faUser
        default:
            return
    }
}

export default determineFAIcon
