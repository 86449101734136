import { styled, theme } from '@/src/stitches.config'

export const DefaultContainer = styled('div', {
    width: '$default',
    maxWidth: '$defaultMax',
    margin: 'auto',
    '.gradient': {
        background: `-webkit-linear-gradient(83.53deg, ${theme.colors.primary} 18.47%, ${theme.colors.secondary} 78%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
})

export const HalfContentContainer = styled('div', {
    flex: 1,
    py: 30,
    '@md': {
        width: '50%',
    },
    variants: {
        background: {
            lightest: {
                backgroundColor: '$lightest',
            },
        },
    },
})

export const FullWidthWrapper = styled('div', {
    width: '100%',
    display: 'flex',
    flexDirection: 'column-reverse',
    '@md': {
        flexDirection: 'row',
    },
    svg: {
        maxWidth: 550,
    },
    variants: {
        flexDirection: {
            column: {
                height: '100%',
                flexDirection: 'column',
                '@md': {
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                },
            },
        },
        width: {
            1440: {
                width: '1440px',
                maxWidth: '100%',
                margin: 'auto',
            },
        },
        background: {
            lightest: {
                background: '$lightest',
            },
        },
    },
    '.gradient': {
        background: `-webkit-linear-gradient(83.53deg, ${theme.colors.primary} 18.47%, ${theme.colors.secondary} 78%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
})

export const Content = styled('div', {
    maxWidth: '90%',
    '@md': {
        maxWidth: '45vw',
    },
})

export const LeftContentWrapper = styled(Content, {
    margin: 'auto',
    position: 'relative',
    '@media (min-width: 1441px)': {
        width: 700,
        maxWidth: '50vw',
        marginLeft: 'auto',
        marginRight: 'unset',
        pr: 100,
    },
})

export const RightContentWrapper = styled(Content, {
    margin: 'auto',
    '@media (min-width: 1440px)': {
        width: 700,
        marginRight: 'auto',
        marginLeft: 'unset',
    },
    '.action-text, & a': {
        color: '$secondary',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    '.action-text': {
        background: 'none',
        border: 'none',
        fontSize: 'inherit',
        fontFamily: 'inherit',
        p: 0,
    },
    h3: {
        my: 5,
    },
})

export const StyledH1 = styled('h1', {
    maxWidth: '100%',
    my: 30,
    '@media (max-width:370px)': {
        fontSize: '2.2rem',
    },
    '@media (min-width: 925px)': {
        width: 460,
        fontSize: '3.25rem',
    },
    variants: {
        size: {
            smaller: {
                fontSize: '2.5rem',
            },
        },
    },
})

export const MessageWrapper = styled('div', {
    display: 'flex',
    height: '100%',
    maxWidth: '$defaultMax',
    margin: 'auto',
    jc: 'center',
    ai: 'center',
})

export const CenterContentContainer = styled('div', {
    display: 'flex',
    fd: 'column',
    width: '$default',
    maxWidth: '$defaultMax',
    margin: 'auto',
    padding: '$3 0',
    alignItems: 'center',
    gap: '$4',
    justifyContent: 'center',
})

export const BasicOverlay = styled('div', {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
})
