import { useQuery } from 'react-query'
import getCart from '../queries/GetCartQuery'
import { useCookies } from 'react-cookie'
import {
    PG_CART_SESSION_UUID,
    PG_CART_STATE_UUID,
    WL_CART_SESSION_UUID,
    WL_CART_STATE_UUID,
} from '@/config/cookieNames'
import { PARTNER_DATA_COOKIE, PARTNER_SLUG } from '@/src/common/constants'

const useGetCartManually = () => {
    const [cookies, setCookie] = useCookies([
        PG_CART_SESSION_UUID,
        PARTNER_DATA_COOKIE,
        PARTNER_SLUG,
        WL_CART_SESSION_UUID,
        WL_CART_STATE_UUID,
    ])
    const hasPartner = Boolean(
        cookies[PARTNER_DATA_COOKIE] || cookies[PARTNER_SLUG]
    )
    const cartSessionUuid =
        cookies[hasPartner ? WL_CART_SESSION_UUID : PG_CART_SESSION_UUID]

    const queryKey = ['cartSessionUuid', cartSessionUuid]
    const { refetch, data, error, isLoading, isSuccess, isError } = useQuery(
        queryKey,
        getCart,
        {
            onSuccess: (cartResponse: {
                cart_session_uuid: string
                cart_state_uuid: string
            }) => {
                // cartResponse could be empty if no cart exists
                if (cartResponse?.cart_session_uuid) {
                    setCookie(
                        hasPartner
                            ? WL_CART_SESSION_UUID
                            : PG_CART_SESSION_UUID,
                        cartResponse.cart_session_uuid,
                        { path: '/', domain: window.location.hostname }
                    )
                    setCookie(
                        hasPartner ? WL_CART_STATE_UUID : PG_CART_STATE_UUID,
                        cartResponse.cart_state_uuid,
                        {
                            path: '/',
                            domain: window.location.hostname,
                        }
                    )
                }
            },
            // @ts-ignore
            refetchOnWindowFocus: false,
            cacheTime: 0, // Cache disable, since this is a manually-triggered
            enabled: false, // This prevents the query from fetching automatically
        }
    )

    return { refetch, data, error, isLoading, isSuccess, isError }
}

export default useGetCartManually
