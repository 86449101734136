import { xhrClient } from '../../../config/axiosClient'

const getCart = async (context: { queryKey: string[] }) => {
    const { data } = await xhrClient.get(`/api/cart`, {
        headers: {
            'x-cart-session-uuid': context.queryKey[1],
        },
    })
    return data
}

export default getCart
