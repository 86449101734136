import {
    embossRegexForm,
    emojiStripRegex,
} from '@/src/modules/build-a-card/constants'
import { trimStart } from 'lodash'

export const cleanCardFieldInput = (string: string, isEmboss = false) => {
    let cleanInput = string.replace(emojiStripRegex, '')

    if (isEmboss) {
        cleanInput = cleanInput.replace(embossRegexForm, '').toUpperCase()
    }

    return trimStart(cleanInput)
}
