import { firebaseAuth } from '@/config/firebaseClientConfig'
import {
    createUserWithEmailAndPassword,
    deleteUser as authDeleteUser,
    FacebookAuthProvider,
    getAuth,
    GoogleAuthProvider,
    OAuthProvider,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut as authSignOut,
    UserCredential,
} from 'firebase/auth'

const signOut = async () => {
    await authSignOut(firebaseAuth)
}

async function deleteUser({
    setIsLoading,
    setError,
}: {
    setIsLoading: Function
    setError: Function
}) {
    const auth = getAuth()
    const user = auth?.currentUser

    if (user) {
        setIsLoading(true)
        authDeleteUser(user)
            .then(() => {
                setError(null)
            })
            .catch(() => {
                setError(
                    'An error occurred while deleting your account. Please try again.'
                )
            })
            .finally(() => setIsLoading(false))
        return
    }

    setError('No user found. Please try again or refresh the page.')
}

const googleSignIn = async () => {
    const provider = new GoogleAuthProvider()
    // provider.addScope('... additional user data to receive with login ...')

    try {
        const res = await signInWithPopup(firebaseAuth, provider)
        const credential = GoogleAuthProvider.credentialFromResult(res)

        return { user: res?.user, accessToken: credential?.accessToken }
    } catch (e: any) {
        if (e?.code !== 'auth/popup-closed-by-user') {
            console.log('Google signin popup closed')
        }
    }
}

const facebookSignIn = async () => {
    const provider = new FacebookAuthProvider()
    // provider.addScope('user_birthday') -> includes birthday with auth data

    try {
        const res = await signInWithPopup(firebaseAuth, provider)
        const credential = GoogleAuthProvider.credentialFromResult(res)

        return { user: res?.user, accessToken: credential?.accessToken }
    } catch (e: any) {
        if (e?.code !== 'auth/popup-closed-by-user') {
            console.log('Facebook signin popup closed')
        }
    }
}

const appleSignIn = async () => {
    const provider = new OAuthProvider('apple.com')

    try {
        const res = await signInWithPopup(firebaseAuth, provider)
        const credential = GoogleAuthProvider.credentialFromResult(res)

        return { user: res?.user, accessToken: credential?.accessToken }
    } catch (e: any) {
        if (e?.code !== 'auth/popup-closed-by-user') {
            console.log('Apple signin popup closed')
        }
    }
}

const emailSignUp = async ({
    email,
    password,
}: {
    email: string
    password: string
}) => {
    try {
        const userCredential: UserCredential =
            await createUserWithEmailAndPassword(firebaseAuth, email, password)

        return { userCredential }
    } catch (e: any) {
        if (e?.code == 'auth/email-already-in-use') {
            return {
                error: {
                    name: '',
                    path: 'email',
                    message: 'Email address already in use.',
                    value: 'email already in use',
                    inner: [],
                    errors: [],
                },
            }
        }

        if (e?.code == 'auth/too-many-requests') {
            return {
                error: {
                    name: '',
                    path: 'password',
                    message:
                        'Too many failed attempts. Please try again later.',
                    value: 'too many failed attempts',
                    inner: [],
                    errors: [],
                },
            }
        }

        return {
            error: {
                name: '',
                path: 'password',
                message:
                    'Something went wrong. Please verify your credentials and try again.',
                value: 'general error',
                inner: [],
                errors: [],
            },
        }
    }
}

const emailSignIn = async ({
    email,
    password,
}: {
    email: string
    password: string
}) => {
    try {
        await signInWithEmailAndPassword(firebaseAuth, email, password)
    } catch (e: any) {
        if (e?.code === 'auth/invalid-credential') {
            return {
                error: {
                    name: '',
                    path: 'password',
                    message: 'Email address or password is invalid',
                    value: 'Incorrect email or password',
                    inner: [],
                    errors: [],
                },
            }
        }

        if (e?.code === 'auth/wrong-password') {
            return {
                error: {
                    name: '',
                    path: 'password',
                    message: 'Incorrect password',
                    value: 'Incorrect password',
                    inner: [],
                    errors: [],
                },
            }
        }

        if (e?.code === 'auth/user-not-found') {
            return {
                error: {
                    name: '',
                    path: 'email',
                    message: 'No account with that email.',
                    value: 'No account with that email',
                    inner: [],
                    errors: [],
                },
            }
        }

        if (e?.code == 'auth/too-many-requests') {
            return {
                error: {
                    name: '',
                    path: 'password',
                    message:
                        'Too many failed attempts. Please try again later.',
                    value: 'too many failed attempts',
                    inner: [],
                    errors: [],
                },
            }
        }

        return {
            error: {
                name: '',
                path: 'password',
                message:
                    'Something went wrong. Please verify your credentials and try again.',
                value: 'general error',
                inner: [],
                errors: [],
            },
        }
    }
}

const resetPassword = async (email: string) => {
    try {
        const auth = getAuth()
        const res = await sendPasswordResetEmail(auth, email)

        return { success: true, data: res }
    } catch (e) {
        return { success: false, data: e }
    }
}

export {
    signOut,
    googleSignIn,
    facebookSignIn,
    appleSignIn,
    emailSignIn,
    emailSignUp,
    resetPassword,
    deleteUser,
}
