import { styled } from '@/src/stitches.config'

export const Text = styled('span', {
    lineHeight: '1',
    margin: '0',
    fontWeight: 400,
    fontVariantNumeric: '$default',
    display: 'inline-block',
    variants: {
        size: {
            '1': {
                fontSize: '$1',
            },
            '2': {
                fontSize: '$2',
            },
            '3': {
                fontSize: '$3',
            },
            '4': {
                fontSize: '$4',
            },
            '5': {
                fontSize: '$5',
            },
            '6': {
                fontSize: '$6',
            },
            '7': {
                fontSize: '$7',
            },
            '8': {
                fontSize: '$8',
            },
            '9': {
                fontSize: '$9',
                letterSpacing: '.8px',
                marginBottom: '1rem',
            },
        },
        weight: {
            bold: {
                fontWeight: 700,
            },
            inherit: {
                fontWeight: 'inherit',
            },
        },
        variant: {
            default: {
                color: '$darkest',
            },
            handWritten: {
                fontFamily: '$handWritten',
            },
        },
        gradient: {
            true: {
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
            },
        },
        fontSizeType: {
            h1: {
                fontSize: '$8',
                '@md': {
                    fontSize: '$9',
                },
            },
            h2: {
                fontSize: '$7',
                '@md': {
                    fontSize: '$8',
                },
            },
            h3: {
                fontSize: '$6',
                '@md': {
                    fontSize: '$7',
                },
            },
            h4: {
                fontSize: '$5',
                '@md': {
                    fontSize: '$6',
                },
            },
            h5: {
                fontSize: '$4',
                '@md': {
                    fontSize: '$5',
                },
            },
            h6: {
                fontSize: '$3',
                '@md': {
                    fontSize: '$4',
                },
            },
            auto: {},
        },
    },
    defaultVariants: {
        fontSizeType: 'auto',
    },
})

export default Text
