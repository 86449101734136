import { useCallback, useEffect, useState } from 'react'
import { importAndSwitchPhoneNumbers } from '../../../../utilities/phoneNumbers'
import { useRouter } from 'next/router'
import {
    CAPITAL_ONE_SLUG,
    CAPITAL_ONE_SALES_PHONE_NUMBER_FORMATTED,
    CAPITAL_ONE_SALES_PHONE_NUMBER_TEL,
    SALES_PHONE_NUMBER_FORMATTED,
    SALES_PHONE_NUMBER_TEL,
} from '../../text/PhoneNumbers/strings'

/**
 * Google Ads contain query parameters with phone numbers for call tracking. Update the site with the ad phone number.
 * @constructor
 */
export const PhoneNumberReplacer = () => {
    const router = useRouter()
    const [formattedPhone, setFormattedPhone] = useState<string | null>(null)
    const [unformattedPhone, setUnformattedPhone] = useState<string | null>(
        null
    )

    const replacePhoneNumbers = useCallback(() => {
        const phoneNumbers = document.querySelectorAll(
            '[data-phone]'
        ) as NodeList

        try {
            setFormattedPhone(window.sessionStorage.formatted_phone_number)
            setUnformattedPhone(window.sessionStorage.unformatted_phone_number)
        } catch {}

        if (router.asPath.includes(CAPITAL_ONE_SLUG)) {
            const promoBannerPhone = document.getElementsByClassName(
                'promo-banner-phone'
            ) as HTMLCollectionOf<HTMLElement>

            promoBannerPhone[0].textContent =
                CAPITAL_ONE_SALES_PHONE_NUMBER_FORMATTED

            const phoneNumberHref = promoBannerPhone[0] as HTMLAnchorElement

            phoneNumberHref.href = CAPITAL_ONE_SALES_PHONE_NUMBER_TEL

            const bannerRightCTA = document.getElementsByClassName(
                'get-a-response'
            ) as HTMLCollectionOf<HTMLElement>

            bannerRightCTA[0].textContent = ''
        } else if (formattedPhone && unformattedPhone) {
            phoneNumbers.length > 0 &&
                phoneNumbers.forEach((phoneNumber) => {
                    phoneNumber.textContent = formattedPhone

                    const phoneNumberHref = phoneNumber as HTMLAnchorElement

                    phoneNumberHref.href = `tel:+1${unformattedPhone}`
                })
        } else {
            phoneNumbers.length > 0 &&
                phoneNumbers.forEach((phoneNumber) => {
                    phoneNumber.textContent = SALES_PHONE_NUMBER_FORMATTED

                    const phoneNumberHref = phoneNumber as HTMLAnchorElement

                    phoneNumberHref.href = SALES_PHONE_NUMBER_TEL
                })
        }
    }, [formattedPhone, router.asPath, unformattedPhone])

    useEffect(() => {
        importAndSwitchPhoneNumbers()
        // This switches the numbers on page load
        replacePhoneNumbers()

        router.events.on('routeChangeComplete', () => {
            // This switches the numbers on page change
            replacePhoneNumbers()
        })

        // If the component is unmounted, unsubscribe from the event with the `off` method:
        return () => {
            router.events.off('routeChangeComplete', () => {
                replacePhoneNumbers()
            })
        }
    }, [replacePhoneNumbers, router.events])

    return <></>
}

export default PhoneNumberReplacer
