import { theme } from '@/src/stitches.config'
import determineFAIcon from '@/src/common/components/elements/Layout/Navigation/determineFAIcon'
import {
    NavDropDownIcon,
    NavHeader,
    SubItemFAIcon,
} from '@/src/common/components/elements/Layout/Navigation/StyledComponents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { INavItemProps } from '@/src/common/components/elements/Layout/Navigation/Mobile/types'
import { returnRotation } from '@/src/common/components/elements/Layout/Navigation/Mobile/utlities'
import replaceGradientWords from '@/src/common/utilities/replaceGradientWords'

const NavigationHeader = ({
    item,
    style,
    isOpen,
    setIsOpen,
    closeMenu,
}: INavItemProps & { isOpen: boolean; setIsOpen: Function }) => {
    const headerStyle = isOpen ? { color: `${theme.colors.primary}` } : style
    const iconStyle = returnRotation(isOpen)
    const FAIcon = determineFAIcon(item?.icon ?? '')
    // handles gradient-ing the text when needed
    const label = item?.gradientText
        ? replaceGradientWords(item?.label, item?.gradientText)
        : item.label

    return (
        <NavHeader
            style={headerStyle}
            className={item?.className ?? ''}
            onClick={() => {
                item?.subSection && setIsOpen(!isOpen)

                if (item?.showChat) {
                    try {
                        // @ts-ignore
                        window.adaEmbed.toggle()
                        closeMenu()
                    } catch {
                        console.log('Unable to open ADA chat window')
                    }
                }
            }}
        >
            {FAIcon && <SubItemFAIcon icon={FAIcon} />}
            {label}
            <NavDropDownIcon style={iconStyle}>
                {item?.subSection && (
                    <FontAwesomeIcon icon={faChevronDown} style={iconStyle} />
                )}
            </NavDropDownIcon>
        </NavHeader>
    )
}

export default NavigationHeader
