class StringHelpers {
    /**
     * Returns true if 'candidate' is a finite number or a string referring (not just 'including') a finite number
     * To keep in mind:
     *   Number(true) = 1
     *   Number('') = 0
     *   Number("   10  ") = 10
     *   !isNaN(true) = true
     *   parseFloat('10 a') = 10
     *
     * @param {?} candidate
     * @return {boolean}
     */
    static isReferringFiniteNumber(candidate: any) {
        if (typeof candidate === 'number') return Number.isFinite(candidate)
        if (typeof candidate === 'string') {
            return candidate.trim() !== '' && Number.isFinite(Number(candidate))
        }
        return false
    }

    static slugify(string: string) {
        return string
            .toLowerCase()
            .trim()
            .replace(/[+]/g, '-plus')
            .replace(/[^\w\s-]/g, '')
            .replace(/[\s_-]+/g, '-')
            .replace(/^-+|-+$/g, '')
    }
}

// TS1208: 'StringHelpers.ts' cannot be compiled under '--isolatedModules'
// because it is considered a global script file. Add an import, export, or an
// empty 'export {}' statement to make it a module.
export default StringHelpers
