import NavigationMenuDropdownItem from '@/src/common/components/elements/Layout/Navigation/NavigationMenuDropdownItem'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import ContactUsText from '@/src/common/components/elements/Text/ContactUsText'
import {
    ChevronIcon,
    LabelMenu,
} from '@/src/common/components/elements/Menus/StyledComponents'
import {
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuList,
    NavigationMenuRoot,
} from '@/src/common/components/elements/Navigation/StyledComponents'
import { PARTNER_ORDER_STATUS_PAGE } from '@/src/modules/pg-plus/constants'

export interface HeaderProps {
    direction?: 'left'
    partner?: string
}

const HeaderHelpMenu = ({ direction, partner }: HeaderProps) => {
    // if user is in partner portal, limit help options
    return (
        <NavigationMenuRoot aria-label={'Help'}>
            <NavigationMenuList>
                <NavigationMenuItem
                    onPointerMove={(event) => event.preventDefault()}
                    onPointerLeave={(event) => event.preventDefault()}
                    className={'hasChildren'}
                    data-testid={'24-7-help-menu'}
                >
                    <LabelMenu>
                        24/7 Help
                        <ChevronIcon icon={faChevronDown} />
                    </LabelMenu>
                    <NavigationMenuContent
                        data-testid={'dropdown-content-wrapper'}
                        direction={direction}
                    >
                        <NavigationMenuDropdownItem
                            label={'Visit our help center'}
                            gradient_words="help center"
                            icon_class={'fa-square-question'}
                            url={
                                'https://perfectgiftsupport.zendesk.com/hc/en-us'
                            }
                            test_id="dt-help-center"
                        />
                        {!partner && (
                            <>
                                <NavigationMenuDropdownItem
                                    label={'Activate your card'}
                                    gradient_words="Activate"
                                    icon_class={'fa-credit-card'}
                                    url={'/activate'}
                                    test_id="dt-activate"
                                />
                                <NavigationMenuDropdownItem
                                    label={'Check balance'}
                                    gradient_words="balance"
                                    icon_class={'fa-receipt'}
                                    url={'/check-balance'}
                                    test_id="dt-check-balance"
                                />
                            </>
                        )}
                        <NavigationMenuDropdownItem
                            label={'Track your order'}
                            gradient_words="Track"
                            icon_class={'fa-truck-fast'}
                            url={
                                partner
                                    ? PARTNER_ORDER_STATUS_PAGE
                                    : '/order-status'
                            }
                            test_id="dt-track-order"
                        />
                        <ContactUsText />
                    </NavigationMenuContent>
                </NavigationMenuItem>
            </NavigationMenuList>
        </NavigationMenuRoot>
    )
}

export default HeaderHelpMenu
