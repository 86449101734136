import * as React from 'react'
import NextLink from 'next/link'
import Button from '../../marketing/buttons/Button'
import { styled, theme } from '../../../../stitches.config'

const ButtonsWrapper = styled('div', {
    display: 'none',
    '@lg': {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
})

const CtaButton = styled(Button, {
    fontFamily: theme.fonts.default,
    fontSize: '.941rem',
    padding: '12px 24px',
    letterSpacing: '.1em',
})

type THeaderButtonProps = {
    isCorporate: boolean
}

const HeaderCtaButtons = ({ isCorporate }: THeaderButtonProps) => {
    return (
        <ButtonsWrapper>
            {isCorporate ? (
                <>
                    <NextLink
                        href={'/visa-gift-card-box'}
                        passHref
                        tabIndex={-1}
                    >
                        <CtaButton
                            label={'SMALL BUSINESS ORDER'}
                            variant={'primary'}
                            size={'sm'}
                            data-testid={'corp-request-account'}
                        />
                    </NextLink>
                    <NextLink href={'/'} passHref tabIndex={-1}>
                        <CtaButton
                            label={'FOR CONSUMER'}
                            variant={'primaryOutline'}
                            size={'sm'}
                            data-testid={'corp-for-individuals'}
                        />
                    </NextLink>
                </>
            ) : (
                <>
                    <NextLink href={'/visa-gift-cards'} passHref tabIndex={-1}>
                        <CtaButton
                            label={'SEND A GIFT'}
                            variant={'primary'}
                            size={'sm'}
                            id={'consumer-send-gift'}
                        />
                    </NextLink>
                    <NextLink href={'/corporate'} passHref tabIndex={-1}>
                        <CtaButton
                            label={'FOR BUSINESS'}
                            variant={'primaryOutline'}
                            size={'sm'}
                            id={'consumer-for-business'}
                        />
                    </NextLink>
                </>
            )}
        </ButtonsWrapper>
    )
}

export default HeaderCtaButtons
