export const DEFAULT_PARTNER_BANNER_TEXT: string = 'Virtual & eGift Cards'

export const PARTNER_GIFT_INFO_STEP: string = 'Information'
export const PARTNER_PAYMENT_STEP: string = 'Payment'
export const PARTNER_COMPLETE_STEP: string = 'Complete'

// mock internal ids for partner programs
export const PNC_POINT_PROGRAM_UUID: string =
    'fd5722ee-8e6a-45f2-89be-e3bb615709fd'
export const PNC_DISCOUNT_PROGRAM_UUID: string =
    'f211c69e-2905-4d66-8fe4-4987890d0504'
export const B_OF_A_MILES_PROGRAM_UUID: string =
    '6a6fb49d-1c52-461a-b72a-adc00a455557'
export const BOJANGLES_PROGRAM_UUID: string =
    'b3fb095f-70a7-4e15-986c-220058e9ae4c'

// mock external ids for partner programs
export const PNC_POINT_PROGRAM_PARTNER_UUID: string = 'mock-points-pnc'
export const PNC_DISCOUNT_PROGRAM_PARTNER_UUID: string = 'mock-discount-pnc'
export const B_OF_A_MILES_PROGRAM_PARTNER_UUID: string = 'mock-miles-bofa'
export const BOJANGLES_PROGRAM_PARTNER_UUID: string = 'mock-bojangles-program'

// mock branded-only brands
export const BOJANGLES_PARTNER_BRAND_UUID: string =
    'aadb6145-9f87-4fb4-a79c-03c72ad6cafd'

// mock point brands
export const DUNKIN_POINTS_PROGRAM_BRAND_UUID: string =
    'ce051c0a-2107-4169-a388-7cd3f7ce69e3'
export const LOWES_POINTS_PROGRAM_BRAND_UUID: string =
    '7fd8a3da-9c6a-4948-a1ce-ca3f20c6a0f7'

// mock mile brands
export const DUNKIN_MILES_PROGRAM_BRAND_UUID: string =
    '4ad69f7c-2a11-493d-9ac1-f649427acd8d'
export const LOWES_MILES_PROGRAM_BRAND_UUID: string =
    'a6110d5e-9b5c-47fa-9fb3-a15ed9590110'

// mock discount brands
export const DUNKIN_DISCOUNT_BRAND_UUID: string =
    '1a20be11-bc93-49a3-a10f-87936a4d94ab'
export const LOWES_DISCOUNT_BRAND_UUID: string =
    'ef0b9b03-9f60-480c-a0b3-f262ba8f3f29'

export const BRAND_SEARCH_DEFAULT_HEADER_TEXT: string = 'Virtual & eGift Cards'
export const BRAND_SEARCH_DEFAULT_HEADER_GRADIENT_TEXT: string =
    'Virtual & eGift Cards'
export const BRAND_SEARCH_DEFAULT_DESCRIPTION_TEXT: string =
    'Send personalized eGifts from popular brands.'
