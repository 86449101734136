import { ICart } from '@/src/modules/cart/ICart'

/**
 * Removes the specified box item from the cart data.
 * @param cart
 * @param boxUuid
 * @returns ICart
 */

export const removeBoxItemFromCart = (cart: ICart, boxUuid: string): ICart => {
    return {
        ...cart,
        cart_contents: {
            deliveries: [
                ...cart.cart_contents.deliveries
                    .map((delivery) => {
                        return {
                            ...delivery,
                            line_items: [
                                ...delivery.line_items.filter((lineItem) => {
                                    return (
                                        lineItem.customization_data
                                            ?.box_uuid !== boxUuid
                                    )
                                }),
                            ],
                        }
                    })
                    .filter((delivery) => {
                        return delivery.line_items.length > 0
                    }),
            ],
        },
    }
}
