import DefaultModal from '@/src/common/components/marketing/modals/DefaultModal'
import { ClipLoader } from 'react-spinners'
import {
    ModalBodyWrapper,
    ModalButtonsWrapper,
} from '@/src/common/components/elements/Modals/StyledModalComponents'
import Button from '@/src/common/components/marketing/buttons/Button'
import { useRouter } from 'next/router'
import { LOGIN_PAGE } from '@/src/modules/account/received/constants'
import { TStarRatingModalTypes } from '@/src/common/components/marketing/reviews/StarRating'

const RatingsModal = ({
    showModal,
    setShowModal,
    rating,
    isComplete,
    messageType,
    brandSlug,
}: {
    showModal: boolean
    setShowModal: Function
    rating: number
    isComplete: boolean
    messageType: string
    brandSlug: string
}) => {
    const router = useRouter()

    const getMessage = () => {
        if (messageType === TStarRatingModalTypes.ERROR) {
            return `There was an unknown error leaving a a rating.  Please try again later.`
        }
        if (messageType === TStarRatingModalTypes.LOGIN) {
            return 'You must be logged in to leave a rating.'
        }
        if (messageType === TStarRatingModalTypes.NEW) {
            return `Thank you for your ${rating} star rating!`
        }
        return `You already rated this brand as ${rating} stars`
    }

    return (
        <DefaultModal
            showModal={showModal}
            setShowModal={setShowModal}
            testId={'ratings-modal'}
            width={'extra-small'}
        >
            {isComplete ? (
                <ModalBodyWrapper>
                    <p style={{ marginTop: 0 }}>{getMessage()}</p>
                    <ModalButtonsWrapper gap={'5px'}>
                        <Button
                            label={'Close'}
                            variant={'primaryOutline'}
                            textTransform={'uppercase'}
                            width={'standard'}
                            showArrow={true}
                            onClick={() => setShowModal(false)}
                        />
                        {messageType === TStarRatingModalTypes.LOGIN && (
                            <Button
                                label={'Login'}
                                variant={'primaryOutline'}
                                textTransform={'uppercase'}
                                width={'standard'}
                                showArrow={true}
                                onClick={() =>
                                    router
                                        .replace(
                                            `${LOGIN_PAGE}/?type=brands/${brandSlug}`
                                        )
                                        .then(() => {})
                                }
                            />
                        )}
                    </ModalButtonsWrapper>
                </ModalBodyWrapper>
            ) : (
                <ClipLoader size={40} />
            )}
        </DefaultModal>
    )
}

export default RatingsModal
