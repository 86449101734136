import { theme } from '@/src/stitches.config'

export const gradientWordsStyles = {
    normal: {
        background: `-webkit-linear-gradient(83.53deg, ${theme.colors.primary} 18.47%, ${theme.colors.secondary} 78%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
    hover: {
        background: theme.colors.secondaryDark,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
}

export const replaceGradientWords = (text: string, gradientWords: string) => {
    const regex = new RegExp(`(${gradientWords})`, 'i')
    const textArray = text.split(regex)

    return textArray.map((str, index) => {
        if (regex.test(str)) {
            return (
                <span className={'gradient-words'} key={`s${index}`}>
                    {str}
                </span>
            )
        }
        return str
    })
}

export default replaceGradientWords
