import useWindowSize from '@/src/common/hooks/useWindowSize'
import { IResolvedCartData, TBoxFees, TBoxItem } from '@/src/modules/cart/ICart'
import {
    CardDetailsContainer,
    CartItemContainer,
    ColumnsContainer,
    ExpandDetails,
    LeftContentContainer,
    LineItemText,
    RightContentContainer,
    Separator,
    SpaceBetweenWrapper,
} from '@/src/modules/cart/StyledComponents'
import { getBoxFees } from '@/src/modules/cart/utilities'
import BoxRemoveAndEditButtons from '@/src/modules/checkout/content/BoxRemoveAndEditButtons'
import ItemFees from '@/src/modules/checkout/content/ItemFees'
import ShippingDetails from '@/src/modules/checkout/content/ShippingDetails'
import { TShippingDetails } from '@/src/modules/checkout/utilities/types'
import SimplifiedBoxImageWithCard from '@/src/modules/gift-box/content/SimplifiedBoxImageWithCard'
import { useState } from 'react'

interface ICartBoxItem {
    box: TBoxItem
    removeItemFromCart: Function
    shipping?: TShippingDetails
    hideBottomBorder?: boolean
}

const CartBoxItem = ({
    box,
    shipping,
    removeItemFromCart,
    hideBottomBorder,
}: ICartBoxItem) => {
    const { width: windowWidth = 1250 } = useWindowSize()
    const isStacked: boolean = windowWidth < 1250
    const [detailsExpanded, setDetailsExpanded] = useState(true)
    const expandedIcon: any = detailsExpanded ? <>&#8211;</> : <>&#43;</>

    const { items: lineItems, box_total_load_value, box_total_price } = box
    const totalPrice = box_total_price.toFixed(2)
    const totalLoadValue = box_total_load_value.toFixed(2)

    const { product, resolved_data, denomination } = lineItems[0]
    const { wrapper_image_url } =
        resolved_data || ({} as Partial<IResolvedCartData>)

    const productBrand = product?.resolved_data?.product_brand ?? ''
    const quantity = lineItems.length
    const boxFees: TBoxFees[] = getBoxFees(box)

    if (!resolved_data) {
        return <></>
    }

    return (
        <>
            <CartItemContainer>
                <LeftContentContainer>
                    <SimplifiedBoxImageWithCard
                        designImage={wrapper_image_url}
                    />
                    <BoxRemoveAndEditButtons
                        removeItemFromCart={removeItemFromCart}
                        box={box}
                    />
                </LeftContentContainer>
                <RightContentContainer>
                    <SpaceBetweenWrapper>
                        <LineItemText fontWeight={'bold'} fontColor={'darkest'}>
                            Plastic {productBrand} Box
                        </LineItemText>
                        <LineItemText fontWeight={'bold'} fontColor={'darkest'}>
                            {`$${totalPrice}`}
                        </LineItemText>
                    </SpaceBetweenWrapper>
                    <ColumnsContainer>
                        <CardDetailsContainer>
                            <LineItemText
                                fontColor={'dark'}
                                fontSize={'smaller'}
                                marginBottom={5}
                            >
                                {`$${denomination}`}
                            </LineItemText>
                            <LineItemText
                                fontColor={'dark'}
                                fontSize={'smaller'}
                                marginBottom={5}
                            >
                                x{quantity} Plastic {productBrand} Cards
                            </LineItemText>
                            {shipping && (
                                <ShippingDetails
                                    shipping={shipping}
                                    detailsExpanded={detailsExpanded}
                                />
                            )}
                        </CardDetailsContainer>
                        <div style={{ marginTop: isStacked ? 10 : 0 }}>
                            <LineItemText
                                fontSize={'smaller'}
                                textAlign={isStacked ? 'left' : 'right'}
                            >
                                Total load value: ${totalLoadValue}
                            </LineItemText>
                            {detailsExpanded && (
                                <ItemFees
                                    fees={boxFees}
                                    textAlign={isStacked ? 'left' : 'right'}
                                    marginTop={0}
                                />
                            )}
                        </div>
                    </ColumnsContainer>
                    <ExpandDetails
                        onClick={() => setDetailsExpanded(!detailsExpanded)}
                    >
                        <p>
                            {expandedIcon} {detailsExpanded ? 'Hide' : 'Show'}{' '}
                            details
                        </p>
                    </ExpandDetails>
                </RightContentContainer>
            </CartItemContainer>

            {!hideBottomBorder && <Separator />}
        </>
    )
}

export default CartBoxItem
