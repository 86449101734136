import { CartProvider } from '@/src/common/contexts/CartContext'
import { FC, useEffect } from 'react'
import ConsumerAndCorporateFooter from './ConsumerAndCorporateFooter'
import CorporateHeader from './CorporateHeader'
import Header from './Header'
import CorpPromoBanner from './Banners/CorpPromoBanner'
import ConsumerBanner from '@/src/common/components/elements/Layout/Banners/ConsumerBanner'
import { useUser } from '@/src/modules/auth/context/AuthProvider'
import {
    setAdaChatMetaField,
    setSensitiveAdaChatMetaField,
} from '@/src/common/utilities/setAdaChatMetaFields'
import useGetFirebaseToken from '@/src/common/hooks/useGetFirebaseToken'
import PartnerBanner from '@/src/common/components/elements/Layout/Banners/PartnerBanner'
import { TPartnerPayload } from '@/src/modules/partners/types'
import PartnerFooter from './PartnerFooter'

interface PagePropsInterface {
    content?: object
    consumerBanner?: any
    corporateBanner?: any
    showFooter?: boolean
    showHeader?: boolean
    partnerData?: TPartnerPayload
    showPartnerBanner?: boolean
    showPartnerFooter?: boolean
}

interface GlobalLayout {
    Component: Function // This is a Page, which is a React Functional Component. May want to make this interface be more specific here.
    pageProps: PagePropsInterface
    isCorporate: boolean
    isLP: boolean
}

function determineHeaderContent(
    isCorporate: boolean,
    isLP: boolean,
    partnerData: TPartnerPayload | undefined,
    corporateBannerHtml: any,
    consumerBannerData: any
) {
    // if isCorporate, show corporate header + banner
    // if not, show consumer header + banner
    // if partner pages, hide consumer banner
    return isCorporate ? (
        <>
            <CorpPromoBanner
                promoBannerHtml={corporateBannerHtml}
                isCorporate={isCorporate}
            />
            <CorporateHeader
                hasHeaderWrapperBorder={isLP}
                enableConsumerDropdownItems={!isLP}
                enableHeaderCtaButtons={!isLP}
                enableHeaderBottom={!isLP}
            />
        </>
    ) : (
        <>
            {consumerBannerData?.length && !partnerData && (
                <ConsumerBanner banners={consumerBannerData} />
            )}
            <Header isCorporate={false} partnerData={partnerData} />
        </>
    )
}

const GlobalLayout: FC<GlobalLayout> = ({
    Component,
    pageProps,
    isCorporate,
    isLP,
}) => {
    const corporateBannerHtml = pageProps?.corporateBanner?.data?.content
    const consumerBannerData = pageProps?.consumerBanner
    const showFooter = pageProps?.showFooter ?? true
    const showHeader = pageProps?.showHeader ?? true
    const partnerData = pageProps?.partnerData
    const showPartnerBanner =
        partnerData && (pageProps?.showPartnerBanner ?? true)
    const showPartnerFooter = pageProps?.showPartnerFooter ?? false

    const { user, isLoggedIn, isLoaded } = useUser()
    const { data: firebaseToken, refetch: refetchFirebaseToken } =
        useGetFirebaseToken()
    const userPhone: string = user?.phoneNumber ?? ''
    const userEmail: string = user?.email ?? ''
    const clearAdaValue: boolean = isLoaded && !isLoggedIn

    useEffect(() => {
        refetchFirebaseToken().then()
    }, [refetchFirebaseToken, user])

    // if user is logged in, send their firebase uid to the chatbot
    // both setMetaFields and setSensitiveMetaFields are available for setting info in chat
    useEffect(() => {
        setSensitiveAdaChatMetaField({
            firebase_token: !clearAdaValue ? firebaseToken : '',
        })

        // @todo determine if we really want/need to pass phone and email in
        setAdaChatMetaField({
            phone_number: !clearAdaValue ? userPhone : '',
            email: !clearAdaValue ? userEmail : '',
        })
    }, [clearAdaValue, firebaseToken, userEmail, userPhone])

    // determines header content (corp vs consumer)
    const headerContent = determineHeaderContent(
        isCorporate,
        isLP,
        partnerData,
        corporateBannerHtml,
        consumerBannerData
    )

    return (
        <CartProvider>
            {showHeader && <>{headerContent}</>}
            {showPartnerBanner && <PartnerBanner partnerData={partnerData} />}
            <main>
                <Component {...pageProps} />
            </main>
            {showFooter && (
                <ConsumerAndCorporateFooter isCorporate={isCorporate} />
            )}
            {showPartnerFooter && (
                <PartnerFooter partnerName={partnerData?.partnerName} />
            )}
        </CartProvider>
    )
}

export default GlobalLayout
