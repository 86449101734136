import { FC } from 'react'
import { styled } from '../../../../stitches.config'
import Image from 'next/image'
import Link from 'next/link'

const NetworkImagesWrapper = styled('div', {
    display: 'flex',
    alignItems: 'center',
})
const NetworkImageWrapper = styled('div', {
    display: 'inline-block',
    minWidth: '61px',
})
const imageCss = { maxWidth: '95%', height: 'auto' }

const HeaderNetworkImages: FC = () => {
    return (
        <NetworkImagesWrapper>
            <NetworkImageWrapper>
                <Link href={'/corporate/visa'} passHref>
                    <Image
                        src={'/images/menus/corp/product_visa.png'}
                        alt="Visa logo"
                        height={20}
                        width={61}
                        style={imageCss}
                        priority
                    />
                </Link>
            </NetworkImageWrapper>
            <NetworkImageWrapper>
                <Link href={'/corporate/mastercard'} passHref>
                    <Image
                        src={'/images/menus/corp/product_mastercard.png'}
                        alt="Mastercard logo"
                        height={20}
                        width={61}
                        style={imageCss}
                        priority
                    />
                </Link>
            </NetworkImageWrapper>
        </NetworkImagesWrapper>
    )
}

export default HeaderNetworkImages
