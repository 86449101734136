import { useMutation } from 'react-query'
import PostUserBrandRating from '@/src/common/queries/PostUserBrandRating'

const usePostUserBrandRating = () => {
    const { mutate, data, error, isLoading, isSuccess, isError } = useMutation(
        'PostUserBrandRating',
        PostUserBrandRating,
        {
            // @ts-ignore
            refetchOnWindowFocus: false,
        }
    )

    return { mutate, data, error, isLoading, isSuccess, isError }
}

export default usePostUserBrandRating