import { useEffect, useState } from 'react'

// gets the current window size
function useWindowSize() {
    const [windowSize, setWindowSize] = useState<{
        height: number | undefined
        width: number | undefined
    }>({
        width: undefined,
        height: undefined,
    })

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }

        window.addEventListener('resize', handleResize)
        handleResize()
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return windowSize
}

export default useWindowSize
