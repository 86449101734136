export function returnRotation(isOpen: boolean) {
    if (isOpen) {
        return {
            transform: 'rotate(180deg)',
            transition: 'transform 0.3s ease-out',
        }
    }
    return {
        transform: 'rotate(0deg)',
        transition: 'transform 0.3s ease-out',
    }
}
