import { styled } from '@/src/stitches.config'

export const ContentWrapper = styled('div', {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
})

export const Arrow = styled('span', {
    marginLeft: 'auto',
    paddingLeft: 5,
})

export const ButtonContentSpacer = () => {
    return <span style={{ marginLeft: 'auto', width: '0%' }} />
}
