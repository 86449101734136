import { styled } from '../../../../../src/stitches.config'
import PrivateLabelPartnerHeader from './PrivateLabelLayoutTemplates/Main/PrivateLabelPartnerHeader'
import PrivateLabelPartnerFooter from './PrivateLabelLayoutTemplates/Main/PrivateLabelPartnerFooter'
import { IPrivateLabelPartnerLayout } from './PrivateLabelLayoutTemplates/IPrivateLabelPartnerLayout'

const BodyWrapper = styled('div', {
    py: 40,
    px: '3vw',
    mx: 'auto',
    my: 'auto',
    minHeight: '100vh',
    backgroundColor: '#8B9CD8',
    '@md': {
        px: '4vw',
    },
    '@lg': {
        px: '5vw',
    },
})

const ContentWrapper = styled('div', {
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    py: 16,
    px: 32,
})

const PrivateLabelPartnerLayout = ({
    Component,
    pageProps,
}: IPrivateLabelPartnerLayout) => {
    return (
        <BodyWrapper css={{ backgroundColor: pageProps!.background_color }}>
            <ContentWrapper>
                <PrivateLabelPartnerHeader
                    partnerLogo={pageProps?.logo_image_url ?? ''}
                />
                <main>
                    <Component {...pageProps} />
                </main>
                <PrivateLabelPartnerFooter />
            </ContentWrapper>
        </BodyWrapper>
    )
}

export default PrivateLabelPartnerLayout
