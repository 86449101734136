import prisma from "@/lib/prisma"
import logger from "@/src/modules/logging/logger"

export const getPhoneNumberParam = (source: string): string | null => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    if (urlParams) {
        return urlParams.get(source)
    }
    return null
}

export const formatPhoneNumber = (phoneNumberString: string): string | null => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        const intlCode = match[1] ? '+1 ' : ''
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return null
}

export const importAndSwitchPhoneNumbers = (): void => {
    const phoneNumbers = process.env.NEXT_PUBLIC_CALL_TRACKING_NUMBERS
    if (!phoneNumbers) {
        return
    }

    const unformattedPhoneNumber = getPhoneNumberParam('phone_number')
    if (!unformattedPhoneNumber) {
        return
    }

    const formattedPhoneNumber = formatPhoneNumber(unformattedPhoneNumber)
    if (!formattedPhoneNumber) {
        return
    }

    if (
        window.sessionStorage.getItem('unformatted_phone_number') ===
            unformattedPhoneNumber &&
        window.sessionStorage.getItem('formatted_phone_number') ===
            formattedPhoneNumber
    ) {
        return
    }

    if (
        phoneNumbers &&
        unformattedPhoneNumber &&
        unformattedPhoneNumber.length === 10 &&
        phoneNumbers.includes(`+1${unformattedPhoneNumber}`)
    ) {
        window.sessionStorage.setItem(
            'formatted_phone_number',
            formattedPhoneNumber
        )
        window.sessionStorage.setItem(
            'unformatted_phone_number',
            unformattedPhoneNumber
        )
    }
}
