import React from 'react'
import * as Popover from '@radix-ui/react-popover'
import { styled } from '@/src/stitches.config'

const StyledContent = styled(Popover.Content, {
    position: 'relative',
    width: 400,
    maxWidth: '90vw',
    zIndex: 1,
    lineHeight: '1.25rem',
    br: 4,
    p: 15,
    fontSize: '.95rem',
    color: '$dark',
    backgroundColor: '$white',
    boxShadow:
        'hsl(206 22% 7% / 35%) 0px 9px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
    '& a': {
        color: '$primary',
        textDecoration: 'underline',
    },
})

const StyledTrigger = styled(Popover.Trigger, {
    br: '50%',
    height: 22,
    width: 21,
    display: 'inline-flex',
    ai: 'center',
    jc: 'center',
    cursor: 'pointer',
    variants: {
        variant: {
            default: {
                color: '$darkest',
                backgroundColor: '$white',
                border: 'solid 1px $dark',
                '&:hover': {
                    background: '$light',
                },
            },
            defaultHideOnSm: {
                display: 'none',
                color: '$darkest',
                backgroundColor: '$white',
                border: 'solid 1px $dark',
                '&:hover': {
                    background: '$light',
                },
                '@md': {
                    display: 'inline-block',
                },
            },
            purple: {
                color: '$primary',
                backgroundColor: '$primaryLight',
                border: 'solid 1px $primaryLight',
                '&:hover': {
                    border: 'solid 1px $primary',
                },
            },
            purpleAlt: {
                color: '$primary',
                backgroundColor: '$primaryLight',
                border: 'solid 1px $primary',
                '&:hover': {
                    filter: 'brightness(1.1)',
                },
            },
        },
    },
    defaultVariant: {
        variants: 'default',
    },
})

const StyledClose = styled(Popover.Close, {
    position: 'absolute',
    right: '1%',
    fontSize: 23,
    border: 'unset',
    background: 'unset',
})

const Arrow = styled(Popover.Arrow, {
    fill: '$white !important',
    color: '$dark !important',
})

export const Trigger = ({
    variant,
    children,
    id,
    ariaLabel,
}: {
    variant: 'default' | 'defaultHideOnSm' | 'purple' | 'purpleAlt'
    children: JSX.Element | JSX.Element[]
    id?: string
    ariaLabel?: string
}) => {
    return (
        <StyledTrigger
            id={id}
            aria-label={ariaLabel}
            data-testid={'popover'}
            variant={variant}
            asChild
        >
            <button>{children}</button>
        </StyledTrigger>
    )
}

export const Content = ({ ...props }) => {
    return (
        <Popover.Portal>
            <StyledContent
                side={'bottom'}
                style={{ width: 400 }}
                sideOffset={3}
                align={'start'}
                collisionPadding={15}
                {...props}
            >
                {props.children}
                <Arrow />
            </StyledContent>
        </Popover.Portal>
    )
}

export const PopoverRoot = Popover.Root
export const PopoverAnchor = Popover.Anchor
export const PopoverTrigger = Trigger
export const PopoverContent = Content
export const PopoverClose = StyledClose
