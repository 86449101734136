import axios from 'axios'
import AxiosMockAdapter from 'axios-mock-adapter'
import {
    xhrCreateCardLinkSessionMock,
    xhrEgiftInfoFulfilled,
    xhrEgiftInfoPending,
    xhrGiftRedemptionBasicInfoDunkinOk1,
    xhrGiftRedemptionBasicInfoEgiftOk1,
    xhrGiftRedemptionBasicInfoExchangeForEgiftBeforeRequested,
    xhrGiftRedemptionBasicInfoPgPlusOk1,
    xhrGiftRedemptionBasicInfoPlasticOk1,
    xhrGiftRedemptionFullInfoDunkinOk1,
    xhrGiftRedemptionFullInfoDunkinUnredeemedOk1,
    xhrGiftRedemptionFullInfoEgiftOk1,
    xhrGiftRedemptionFullInfoExchangeForEgiftBeforeRequested,
    xhrGiftRedemptionFullInfoPgPlusOk1,
    xhrGiftRedemptionFullInfoPlasticOk1,
    xhrReceivedGiftDunkinEGift,
    xhrReceivedGiftLinkedFullyUsed,
    xhrReceivedGiftLowesEGift,
    xhrReceivedGiftPlasticFullyUsed,
    xhrReceivedGifts,
    xhrReceivedGiftUnredeemed,
    xhrReceivedGiftVirtualVisa,
    xhrVirtualConsumerBasicInfoOk1,
} from './xhrApiClientAxiosMockResponses'
import {
    API_CREATE_CARD_LINKING_SESSION,
    API_DEACTIVATE_CARD_LINK,
    API_DELETE_CARD,
    API_EGIFT_EXCHANGE,
    API_EGIFT_INFO,
    API_GIFT_RECEIVED_DETAILS,
    API_GIFT_REDEMPTION_BASIC_INFO,
    API_GIFT_REDEMPTION_FULL_INFO,
    API_GIFT_REDEMPTION_PLASTIC_VISA,
    API_RECEIVED_GIFTS,
} from '@/config/xhrApiClientRouteNames'
import {
    CARD_TO_CARD_REDEMPTION_CODE_TEST_DUNKIN,
    CARD_TO_CARD_REDEMPTION_CODE_TEST_EGIFT,
    CARD_TO_CARD_REDEMPTION_CODE_TEST_EXCHANGE_FOR_EGIFT,
    CARD_TO_CARD_REDEMPTION_CODE_TEST_PG_PLUS,
    CARD_TO_CARD_REDEMPTION_CODE_TEST_PLASTIC,
    CARD_TO_CARD_REDEMPTION_TOKEN_TEST_DUNKIN,
    CARD_TO_CARD_REDEMPTION_TOKEN_TEST_EGIFT,
    CARD_TO_CARD_REDEMPTION_TOKEN_TEST_EXCHANGE_FOR_EGIFT,
    CARD_TO_CARD_REDEMPTION_TOKEN_TEST_PG_PLUS,
    CARD_TO_CARD_REDEMPTION_TOKEN_TEST_PLASTIC,
    CARD_TO_CARD_REDEMPTION_TOKEN_TEST_UNREDEEMED_DUNKIN,
    CARD_TO_CARD_UUID_TEST_PG_PLUS,
} from '@/prisma/schemaConstants'
import {
    CARD_LINKED_UUID,
    DUNKIN_EGIFT_UUID,
    LOWES_EGIFT_UUID,
    PLASTIC_UUID,
    UNREDEEMED_GIFT_UUID,
    VIRTUAL_VISA_UUID,
} from '@/src/modules/account/received/constants'

export const xhrApiClientMock = axios.create({
    // baseURL: '/',
})
const xhrAxiosMockAdapter = new AxiosMockAdapter(xhrApiClientMock, {
    delayResponse: 250,
})

xhrAxiosMockAdapter
    .onPost('/api/consumer-virtual-redemption-basic-info')
    .reply(200, xhrVirtualConsumerBasicInfoOk1)

xhrAxiosMockAdapter.onOptions(API_GIFT_REDEMPTION_BASIC_INFO).reply(200)

// "Basic info" =========================================================

xhrAxiosMockAdapter
    .onPost(API_GIFT_REDEMPTION_BASIC_INFO, {
        token: CARD_TO_CARD_REDEMPTION_TOKEN_TEST_DUNKIN,
    })
    .reply(200, xhrGiftRedemptionBasicInfoDunkinOk1)

xhrAxiosMockAdapter
    .onPost(API_GIFT_REDEMPTION_BASIC_INFO, {
        token: CARD_TO_CARD_REDEMPTION_TOKEN_TEST_UNREDEEMED_DUNKIN,
    })
    .reply(200, xhrGiftRedemptionBasicInfoDunkinOk1)

xhrAxiosMockAdapter
    .onPost(API_GIFT_REDEMPTION_BASIC_INFO, {
        token: CARD_TO_CARD_REDEMPTION_TOKEN_TEST_PG_PLUS,
    })
    .reply(200, xhrGiftRedemptionBasicInfoPgPlusOk1)
xhrAxiosMockAdapter
    .onPost(API_GIFT_REDEMPTION_BASIC_INFO, {
        token: CARD_TO_CARD_REDEMPTION_TOKEN_TEST_EGIFT,
    })
    .reply(200, xhrGiftRedemptionBasicInfoEgiftOk1)
xhrAxiosMockAdapter
    .onPost(API_GIFT_REDEMPTION_BASIC_INFO, {
        token: CARD_TO_CARD_REDEMPTION_TOKEN_TEST_PLASTIC,
    })
    .reply(200, xhrGiftRedemptionBasicInfoPlasticOk1)
xhrAxiosMockAdapter
    .onPost(API_GIFT_REDEMPTION_BASIC_INFO, {
        token: CARD_TO_CARD_REDEMPTION_TOKEN_TEST_EXCHANGE_FOR_EGIFT,
    })
    .replyOnce(200, xhrGiftRedemptionBasicInfoExchangeForEgiftBeforeRequested)

// "Full info" =========================================================

xhrAxiosMockAdapter
    .onPost(API_GIFT_REDEMPTION_FULL_INFO, {
        token: CARD_TO_CARD_REDEMPTION_TOKEN_TEST_DUNKIN,
        code: CARD_TO_CARD_REDEMPTION_CODE_TEST_DUNKIN,
    })
    .reply(200, xhrGiftRedemptionFullInfoDunkinOk1)

xhrAxiosMockAdapter
    .onPost(API_GIFT_REDEMPTION_FULL_INFO, {
        token: CARD_TO_CARD_REDEMPTION_TOKEN_TEST_UNREDEEMED_DUNKIN,
        code: CARD_TO_CARD_REDEMPTION_CODE_TEST_DUNKIN,
    })
    .reply(200, xhrGiftRedemptionFullInfoDunkinUnredeemedOk1)

xhrAxiosMockAdapter
    .onPost(API_GIFT_REDEMPTION_FULL_INFO, {
        token: CARD_TO_CARD_REDEMPTION_TOKEN_TEST_PG_PLUS,
        code: CARD_TO_CARD_REDEMPTION_CODE_TEST_PG_PLUS,
    })
    .reply(200, xhrGiftRedemptionFullInfoPgPlusOk1)
xhrAxiosMockAdapter
    .onPost(API_GIFT_REDEMPTION_FULL_INFO, {
        token: CARD_TO_CARD_REDEMPTION_TOKEN_TEST_EGIFT,
        code: CARD_TO_CARD_REDEMPTION_CODE_TEST_EGIFT,
    })
    .reply(200, xhrGiftRedemptionFullInfoEgiftOk1)
xhrAxiosMockAdapter
    .onPost(API_GIFT_REDEMPTION_FULL_INFO, {
        token: CARD_TO_CARD_REDEMPTION_TOKEN_TEST_PLASTIC,
        code: CARD_TO_CARD_REDEMPTION_CODE_TEST_PLASTIC,
    })
    .reply(200, xhrGiftRedemptionFullInfoPlasticOk1)
xhrAxiosMockAdapter
    .onPost(API_GIFT_REDEMPTION_FULL_INFO, {
        token: CARD_TO_CARD_REDEMPTION_TOKEN_TEST_EXCHANGE_FOR_EGIFT,
        code: CARD_TO_CARD_REDEMPTION_CODE_TEST_EXCHANGE_FOR_EGIFT,
    })
    .reply(
        (config: any) =>
            new Promise((resolve, reject) =>
                setTimeout(
                    async () =>
                        resolve([
                            200,
                            xhrGiftRedemptionFullInfoExchangeForEgiftBeforeRequested,
                        ]),
                    2000
                )
            )
    )

// Exchange plastic ============================================================

xhrAxiosMockAdapter
    .onPost(API_GIFT_REDEMPTION_PLASTIC_VISA, {
        asymmetricMatch: function (actual: any) {
            return actual?.card_uuid === CARD_TO_CARD_UUID_TEST_PG_PLUS
        },
    })
    .reply(201)

// Request egift  =========================================================

xhrAxiosMockAdapter.onPost(API_EGIFT_EXCHANGE).reply((config: any) => {
    console.log({ config })
    return new Promise((resolve, reject) =>
        setTimeout(
            async () =>
                resolve([
                    200,
                    xhrGiftRedemptionFullInfoExchangeForEgiftBeforeRequested,
                ]),
            // reject([400, { error: 'FOo bar' }]),
            2000
        )
    )
})

// Fetch egift  =========================================================

const routeBase = API_EGIFT_INFO
// TODO: replace \S* with \S{36} , after changing mock data from 'fake-c2c-uuid' to a real uuid4
const regex = /\/api\/gift-redemption-egift-info\/\S{1,}/

xhrAxiosMockAdapter
    .onGet(regex)
    .replyOnce(404)

    .onGet(regex)
    .replyOnce(400)

    .onGet(regex)
    .replyOnce(500)

    .onGet(regex)
    .replyOnce(200, xhrEgiftInfoPending)

    .onGet(regex)
    .replyOnce(200, xhrEgiftInfoPending)

    .onGet(regex)
    .replyOnce(200, xhrEgiftInfoPending)

    .onGet(regex)
    .replyOnce(200, xhrEgiftInfoFulfilled)

    .onGet(regex)
    .replyOnce(200, { status: 'NEVER SHOULD BE FETCHED!!' })

// Fetch received gifts  =========================================================

xhrAxiosMockAdapter
    .onGet(API_RECEIVED_GIFTS)
    .reply(200, { data: xhrReceivedGifts })

xhrAxiosMockAdapter
    .onGet(API_GIFT_RECEIVED_DETAILS, {
        data: { uuid: UNREDEEMED_GIFT_UUID },
    })
    .reply(200, { data: xhrReceivedGiftUnredeemed })

xhrAxiosMockAdapter
    .onGet(API_GIFT_RECEIVED_DETAILS, { data: { uuid: PLASTIC_UUID } })
    .reply(200, { data: xhrReceivedGiftPlasticFullyUsed })

xhrAxiosMockAdapter
    .onGet(API_GIFT_RECEIVED_DETAILS, { data: { uuid: VIRTUAL_VISA_UUID } })
    .reply(200, { data: xhrReceivedGiftVirtualVisa })

xhrAxiosMockAdapter
    .onGet(API_GIFT_RECEIVED_DETAILS, { data: { uuid: DUNKIN_EGIFT_UUID } })
    .reply(200, { data: xhrReceivedGiftDunkinEGift })

xhrAxiosMockAdapter
    .onGet(API_GIFT_RECEIVED_DETAILS, { data: { uuid: LOWES_EGIFT_UUID } })
    .reply(200, { data: xhrReceivedGiftLowesEGift })

xhrAxiosMockAdapter
    .onGet(API_GIFT_RECEIVED_DETAILS, { data: { uuid: CARD_LINKED_UUID } })
    .reply(200, { data: xhrReceivedGiftLinkedFullyUsed })

// Create card linking session  ================================================
xhrAxiosMockAdapter
    .onPost(API_CREATE_CARD_LINKING_SESSION)
    .reply(200, xhrCreateCardLinkSessionMock)

// Deactivate card link ========================================================

xhrAxiosMockAdapter
    .onPost(API_DEACTIVATE_CARD_LINK)
    .reply(200, { message: 'successfully deactivated' })

// Delete / unenroll card =======================================================

xhrAxiosMockAdapter
    .onPost(API_DELETE_CARD)
    .reply(200, { message: 'successfully deleted card' })
