import React, {useState} from 'react'
import { Rating } from 'react-simple-star-rating'
import RatingsModal from '@/src/modules/pg-plus/content/RatingsModal'
import { useUser } from '@/src/modules/auth/context/AuthProvider'
import usePostUserBrandRating from '@/src/common/hooks/usePostUserBrandRating'

export enum TStarRatingModalTypes {
    LOGIN = 'login',
    EXISTING = 'existing',
    NEW = 'new',
    ERROR = 'error',
}

interface StarRatingProps {
    ratingValue: number
    starSize: number
    fillColor?: string | undefined
    emptyColor?: string | undefined
    readonly?: boolean
    brandUuid?: string | undefined
    brandId?: number | undefined
    brandSlug?: string | undefined
}

export const StarRating = ({
    ratingValue,
    fillColor,
    emptyColor,
    starSize,
    readonly = true,
    brandId,
    brandSlug
}: StarRatingProps) => {
    const [showModal, setShowModal] = useState<boolean>(false)
    const [userRating, setUserRating] = useState<number>(0)
    const [ratingComplete, setRatingComplete] = useState<boolean>(true)
    const [messageType, setMessageType] = useState<string>('new')
    const { isLoggedIn, user } = useUser()
    const {
        mutate: postUserBrandRating,
        isLoading: userBrandPostIsLoading,
        isSuccess: userBrandPostIsSuccess
    } = usePostUserBrandRating()

    const handleRating = (rate: number) => {
        // check logged in - if not, show must log in modal
        const wholeRating = Math.ceil(rate)
        if (!brandId) {
            return
        }
        
        if (!isLoggedIn) {
            setMessageType(TStarRatingModalTypes.LOGIN)
            setRatingComplete(true)
            setShowModal(true)
            return
        }
        const storedRating = localStorage.getItem(`pgcBrandRating-${user!.uid}-${brandId}`);
        // User already rated
        if (storedRating) {
            setUserRating(parseInt(storedRating))
            setMessageType(TStarRatingModalTypes.EXISTING)
            setRatingComplete(true)
            setShowModal(true)
            return
        }
        
        // is a new rating
        const userRatingPayload = {
            brand_id: brandId,
            rating: wholeRating
        }
        postUserBrandRating(userRatingPayload, {
            onSuccess: () => {
                setUserRating(wholeRating)
                localStorage.setItem(`pgcBrandRating-${user!.uid}-${brandId}`, wholeRating.toString());
                setMessageType(TStarRatingModalTypes.NEW)
                setShowModal(true)
            },
            onError: () => {
                setMessageType(TStarRatingModalTypes.ERROR)
                setShowModal(true)
            },
        })
    }

    return (
        <>
            <Rating
                initialValue={ratingValue}
                fillColor={fillColor}
                emptyColor={emptyColor}
                size={starSize}
                readonly={readonly}
                allowFraction
                // These following two props are needed to prevent a React error. See https://wolfellc.atlassian.net/browse/CON-786
                style={{ cursor: 'default' }}
                transition
                onClick={handleRating}
            />
            <RatingsModal
                showModal={showModal}
                setShowModal={setShowModal}
                rating={userRating}
                isComplete={ratingComplete}
                messageType={messageType}
                brandSlug={brandSlug ? brandSlug : ''}
            />
        </>
    )
}

export default StarRating
