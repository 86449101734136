import { styled } from '@/src/stitches.config'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { IconButton } from '../../../Buttons/IconButton'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import MobileNavigation from '@/src/common/components/elements/Layout/Navigation/Mobile/MobileNavigation'

const MobileNavToggleWrapper = styled('div', {
    display: 'flex',
    p: 5,
    '@md': {
        display: 'none',
    },
})
const MobileNavToggle = styled(IconButton, {
    cursor: 'pointer',
    fontSize: '$6',
    position: 'relative',
    zIndex: 1,
    '@sm': {
        fontSize: '$7',
    },
})

interface IMobileNavProps {
    isCorporate: boolean
    partner?: string
}

const MobileNavDrawer = ({ isCorporate, partner }: IMobileNavProps) => {
    const router = useRouter()
    const [isOpen, setIsOpen] = useState(false)
    const [menuIsClosing, setMenuIsClosing] = useState<boolean>(false)

    const closeDrawer = () => {
        setIsOpen(false)
    }
    const toggleDrawer = () => {
        setMenuIsClosing(!isOpen)
        setIsOpen((prevState) => !prevState)
    }

    useEffect(() => {
        router.events.on('routeChangeComplete', closeDrawer)
        return () => {
            router.events.off('routeChangeComplete', closeDrawer)
        }
    }, [router.events])

    return (
        <MobileNavToggleWrapper>
            <MobileNavToggle
                data-testid="hamburger-menu-icon"
                variant="ghost"
                onClick={toggleDrawer}
            >
                <FontAwesomeIcon icon={faBars} />
            </MobileNavToggle>
            <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction="left"
                className="mobile-nav-drawer"
                customIdSuffix="_pgc_mobile_nav_drawer"
                style={{ overflowY: 'auto', width: 270 }}
            >
                <MobileNavigation
                    isCorporate={isCorporate}
                    partner={partner}
                    closeMenu={toggleDrawer}
                    menuIsClosing={menuIsClosing}
                    setMenuIsClosing={setMenuIsClosing}
                />
            </Drawer>
        </MobileNavToggleWrapper>
    )
}

export default MobileNavDrawer
