import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { onAuthStateChanged, User } from 'firebase/auth'
import { firebaseAuth } from '@/config/firebaseClientConfig'
import { identify } from '@fullstory/browser'

const AuthContext = createContext<{
    user: User | null
    userLoaded: boolean
    refreshUser: Function
}>({
    user: null,
    userLoaded: false,
    refreshUser: () => {},
})

export const AuthProvider = ({ children }: any) => {
    const [user, setUser] = useState<User | null>(null)
    const [userLoaded, setUserLoaded] = useState(false)

    const refreshUser = async () => {
        if (firebaseAuth.currentUser) {
            await firebaseAuth.currentUser.reload()
            setUser(JSON.parse(JSON.stringify(firebaseAuth.currentUser)))
        }
    }

    const value = useMemo(
        () => ({ user, userLoaded, refreshUser }),
        [user, userLoaded]
    )

    const handleWindowFocus = () => {
        refreshUser().then()
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
            setUser(user)
            setUserLoaded(true)
            if (user) {
                try {
                    identify(user.uid, {
                        displayName: user.displayName!,
                        email: user?.email ?? '',
                        phone: user?.phoneNumber ?? '',
                    })
                } catch (e) {
                    console.error(e)
                }
            }
        })

        window.addEventListener('focus', handleWindowFocus)

        return () => {
            unsubscribe()
            window.removeEventListener('focus', handleWindowFocus)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useUser = () => {
    const { user, refreshUser, userLoaded } = useContext(AuthContext)

    return {
        user,
        refreshUser,
        isLoaded: userLoaded,
        isLoggedIn: !!user,
        isVerified: isUserVerified(user),
        authProviders: getUserAuthProviders(user),
        userLoginMethod: getUserLoginMethod(user),
    }
}

function isUserVerified(user: User | null) {
    if (!user) return false
    if (user?.emailVerified) return true
    if (user?.phoneNumber) return true
    if (
        user?.providerData?.find(
            ({ providerId }) => providerId === 'facebook.com'
        )
    ) {
        return true
    }
    return false
}

function getUserAuthProviders(user: User | null) {
    // will be used with account linking and account page
    // to simplify the process of getting all user providers
    // (facebook, google, phone, email/password)
    return []
}

function getUserLoginMethod(user: User | null): string {
    if (user?.email) return `${user.email}`
    if (user?.phoneNumber) return `${user.phoneNumber}`
    return ''
}
