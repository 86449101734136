import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { PARTNER_DATA_COOKIE, PARTNER_SLUG } from '@/src/common/constants'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'
import { WL_CART_SESSION_UUID, WL_CART_STATE_UUID } from '@/config/cookieNames'

type TCartPropertiesState = {
    cartTotalCards: number
    cartTotalLoad: number
    cartFlyoutOpen: boolean
    setCartTotalCards: Function
    setCartTotalLoad: Function
    setCartFlyoutOpen: Function
    setOverLimitRequiresKYC: Function
    overLimitRequiresKYC: boolean
    showKYCForm: boolean
    setShowKYCForm: Function
}

export const CartContext = createContext<TCartPropertiesState | null>(null)

export const useCartContext = (): TCartPropertiesState => {
    const context = useContext(CartContext)

    if (!context) {
        throw new Error('Please use CartContext in parent component')
    }

    return context
}

function clearWhiteLabelStates(
    removeCookie: Function,
    clearPartnerData: boolean
) {
    // only clear partner data when prompted
    if (clearPartnerData) {
        removeCookie(PARTNER_DATA_COOKIE, {
            path: '/',
            domain: window.location.hostname,
            sameSite: 'strict',
        })
        removeCookie(PARTNER_SLUG, {
            path: '/',
            domain: window.location.hostname,
            sameSite: 'strict',
        })
    }

    removeCookie(WL_CART_STATE_UUID, {
        path: '/',
        domain: window.location.hostname,
    })
    removeCookie(WL_CART_SESSION_UUID, {
        path: '/',
        domain: window.location.hostname,
    })
}

export const CartProvider = ({ children }: { children: ReactNode }) => {
    const [cartTotalCards, setCartTotalCards] = useState<number>(0)
    const [cartTotalLoad, setCartTotalLoad] = useState<number>(0)
    const [cartFlyoutOpen, setCartFlyoutOpen] = useState<boolean>(false)
    const [overLimitRequiresKYC, setOverLimitRequiresKYC] =
        useState<boolean>(false)
    const [showKYCForm, setShowKYCForm] = useState<boolean>(false)

    const router = useRouter()
    const [cookies, setCookie, removeCookie] = useCookies([
        PARTNER_DATA_COOKIE,
        WL_CART_SESSION_UUID,
        WL_CART_STATE_UUID,
    ])
    const urlPath = router.asPath
    const hasPartnerJWT: boolean = !!router?.query?.token
    const hasPartnerData = Boolean(
        cookies[PARTNER_DATA_COOKIE] || cookies[PARTNER_SLUG]
    )

    // if user exits the partner flow, remove the stored cookie
    useEffect(() => {
        const isInPartnerFlow: boolean = urlPath.includes('/partner/')
        const isNotAcceptedPartnerPath =
            !isInPartnerFlow &&
            !urlPath.includes('/checkout/amazon-pay-redirect')

        // if user has stored partner data and is outside of partner flow, remove cookies
        if (hasPartnerData && isNotAcceptedPartnerPath) {
            clearWhiteLabelStates(removeCookie, true)
            return
        }

        // if user is in partner flow, is passing in a new jwt,
        // and already has partner data stored, remove cookie
        if (isInPartnerFlow && hasPartnerJWT && hasPartnerData) {
            clearWhiteLabelStates(removeCookie, false)
        }
    }, [hasPartnerData, hasPartnerJWT, removeCookie, urlPath])

    const values = useMemo(
        () => ({
            cartTotalCards,
            cartTotalLoad,
            cartFlyoutOpen,
            overLimitRequiresKYC,
            showKYCForm,
            setCartTotalCards,
            setCartTotalLoad,
            setCartFlyoutOpen,
            setOverLimitRequiresKYC,
            setShowKYCForm,
        }),
        [
            cartTotalCards,
            cartTotalLoad,
            cartFlyoutOpen,
            overLimitRequiresKYC,
            showKYCForm,
        ]
    )

    return (
        <CartContext.Provider value={values}>{children}</CartContext.Provider>
    )
}
