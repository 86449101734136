import Card from '@/src/common/components/marketing/cards/Card'
import Image from 'next/image'
import { GiftBox } from '@/src/modules/gift-box/content/StyledComponents'

const SimplifiedBoxImageWithCard = ({
    designImage,
    priority = false,
}: {
    designImage: string
    priority?: boolean
}) => {
    return (
        <GiftBox className={'gift-box-wrapper'}>
            <Image
                src={'/images/gift-box-image-v2.png'}
                alt={'PerfectGift Box Image'}
                width={640}
                height={438}
                className={'gift-box-image'}
                priority={priority}
            />
            <Card
                size={'card-for-box'}
                image={designImage}
                overlayType={'full'}
                isExtended={false}
                showLoader={!designImage}
                priority={priority}
                showCustomFields={false}
                borderRadius={5}
            />
        </GiftBox>
    )
}

export default SimplifiedBoxImageWithCard
