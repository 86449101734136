import {
    CardFeesContainer,
    LineItemText,
} from '@/src/modules/cart/StyledComponents'
import { IFee, TBoxFees } from '@/src/modules/cart/ICart'

const ItemFees = ({
    fees,
    marginTop = 5,
    textAlign = 'right',
}: {
    fees: IFee[] | TBoxFees[]
    marginTop?: number | string
    textAlign?: 'left' | 'right'
}) => {
    return (
        <CardFeesContainer style={{ marginTop: marginTop }}>
            {fees.map((fee) => {
                return (
                    <LineItemText
                        key={fee?.uuid}
                        fontColor={'dark'}
                        textAlign={textAlign}
                        fontSize={'smaller'}
                    >
                        {`${fee.fee_description}: $${fee.amount}`}
                    </LineItemText>
                )
            })}
        </CardFeesContainer>
    )
}

export default ItemFees
