export const SALES_PHONE_NUMBER_DATA_ID = 'phone-number'
export const SALES_PHONE_NUMBER_FORMATTED = '(800) 543-7927'
export const SALES_PHONE_NUMBER_TEL = 'tel:+18005437927'

export const SUPPORT_PHONE_NUMBER_FORMATTED = '(877) 448-4438'
export const SUPPORT_PHONE_NUMBER_TEL = 'tel:+18774484438'

export const SUPPORT_SMS_PHONE_NUMBER_FORMATTED = '(412) 912-4341'
export const SUPPORT_SMS_PHONE_NUMBER_TEL = 'sms:+14129124341'

export const CAPITAL_ONE_SLUG = '/corporate/capital-one-spring'
export const CAPITAL_ONE_SALES_PHONE_NUMBER_FORMATTED = '(888) 505-5782'
export const CAPITAL_ONE_SALES_PHONE_NUMBER_TEL = 'tel:+18885055782'
