import {
    addBoxRemovedFromCartAnalyticEvent,
    addItemRemovedFromCartAnalyticEvent,
} from '@/src/common/utilities/gtag'
import {
    ICart,
    IDelivery,
    ILineItem,
    TBoxFees,
    TBoxItem,
} from '@/src/modules/cart/ICart'
import flatMap from 'lodash/flatMap'

export function removeCartItem({
    removeItemFromCart,
    line_item_uuid,
    cartLineItem,
    mediumType,
}: {
    removeItemFromCart: Function
    line_item_uuid: string
    cartLineItem: ILineItem
    mediumType: string
}) {
    removeItemFromCart({ lineItemUuid: line_item_uuid })
    addItemRemovedFromCartAnalyticEvent(cartLineItem, mediumType)
}

export function removeBoxItemAndSendAnalyticEvent({
    removeItemFromCart,
    boxItem,
}: {
    removeItemFromCart: Function
    boxItem: TBoxItem
}) {
    removeItemFromCart({ boxUuid: boxItem.box_uuid })
    addBoxRemovedFromCartAnalyticEvent(boxItem.items)
}

export function getLineItemsForCart(cart?: ICart | null) {
    if (!cart?.cart_contents?.deliveries) {
        return {
            lineItemsInBoxes: [],
            lineItemsNotInBox: [],
            allLineItems: [],
        }
    }

    const lineItemsNotInBox: ILineItem[] = []
    const lineItemsInBoxes: TBoxItem[] = []
    const allLineItems: ILineItem[] = flatMap(
        cart?.cart_contents.deliveries,
        (delivery: IDelivery) => {
            let updatedLineItems: ILineItem[] = []
            for (const itm of delivery.line_items) {
                const lineItem = {
                    ...itm,
                }
                if (
                    delivery.delivery_option?.resolved_data?.service
                        ?.service_name === 'SMS Text'
                ) {
                    lineItem.recipient_phone_number =
                        delivery.delivery_details.recipient_phone_number
                } else if (
                    delivery.delivery_option?.resolved_data?.service
                        ?.service_name === 'Email Delivery'
                ) {
                    lineItem.recipient_email_address =
                        delivery.delivery_details.recipient_email_address
                }
                updatedLineItems.push(lineItem)
            }

            return updatedLineItems
        }
    )

    cart.cart_contents.deliveries.forEach((delivery) => {
        delivery.line_items.forEach((item) => {
            const boxUuid: string | undefined =
                item.customization_data?.box_uuid

            if (delivery.delivery_details?.address) {
                item.shipping = {
                    details: delivery.delivery_details,
                    option:
                        delivery.delivery_option?.resolved_data?.carrier
                            ?.carrier_name ?? '',
                }
            }

            if (boxUuid) {
                const itemTotalPrice: number = Number(
                    item?.resolved_data?.total_price ?? 0
                )

                // see if its box already exists. if it does, put the item in that box. if not, add a new box
                const existingBox: TBoxItem | undefined =
                    lineItemsInBoxes?.find((box) => box.box_uuid === boxUuid)

                // if the box exists, add this item's total price and the item to the box
                if (existingBox) {
                    existingBox.box_total_price += itemTotalPrice
                    existingBox.box_total_load_value += Number(
                        item.denomination
                    )
                    existingBox.items.push(item)

                    return
                }

                lineItemsInBoxes.push({
                    box_uuid: boxUuid,
                    box_total_load_value: Number(item.denomination),
                    box_total_price: itemTotalPrice,
                    items: [item],
                })

                return
            }

            if (delivery.delivery_details?.recipient_phone_number) {
                item.recipient_phone_number = delivery.delivery_details.recipient_phone_number
            } else if (delivery.delivery_details?.recipient_email_address) {
                item.recipient_email_address = delivery.delivery_details.recipient_email_address
            }
            lineItemsNotInBox.push(item)
        })
    })

    return {
        lineItemsInBoxes,
        lineItemsNotInBox,
        allLineItems,
    }
}

export function getBoxFees(box: TBoxItem) {
    const boxFees: TBoxFees[] = []

    // look at all box items and see if their fees have been accounted for
    box?.items?.forEach((item) => {
        item?.resolved_data?.fees.forEach((fee) => {
            const existingFee = boxFees?.find(
                (boxFee) => boxFee.fee_description === fee?.fee_description
            )
            // if it hasn't, add it
            if (!existingFee) {
                boxFees.push({
                    fee_description: fee.fee_description,
                    amount: fee.fee_rate.toFixed(2),
                    uuid: fee.fee_description,
                })
                return
            }
            // if it does, add up each item's fees to get the total fee
            existingFee.amount = (
                Number(existingFee.amount) + fee.fee_rate
            ).toFixed(2)
        })
    })

    return boxFees
}
