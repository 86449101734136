import { xhrApiClient } from "@/config/xhrApiClient"

export type TPostUserBrandRatingData = {
    rating: number
    brand_id: number
}

export type TPostUserBrandRatingResult = {
    error?: string
    message?: string
}

const PostUserBrandRating = async (
    payload: TPostUserBrandRatingData
): Promise<TPostUserBrandRatingResult> => {
    return await xhrApiClient.post('/api/user-brand-rating', payload)
}

export default PostUserBrandRating