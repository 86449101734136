import { Dialog, DialogClose } from '../../../components/elements/Modals/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark'
import {
    ModalComponent,
    ModalContent,
    ModalIconButton,
} from '@/src/common/components/elements/Modals/StyledModalComponents'
import handleEnterPressEvent from '@/src/common/utilities/handleEnterPressEvent'
import { TModalHeightAndWidth } from '@/src/common/components/elements/Modals/types'

interface ModalProps extends TModalHeightAndWidth {
    showModal: boolean
    setShowModal: Function
    children?: JSX.Element | JSX.Element[]
    testId: string
    variant?: 'iframe'
    onOpenAutoFocus?: Function
}

const DefaultModal = ({
    showModal,
    setShowModal,
    children,
    testId,
    width = 'small',
    height = undefined,
    variant,
    onOpenAutoFocus = () => {},
}: ModalProps) => {
    return (
        <Dialog open={showModal}>
            <ModalComponent
                data-testid={testId}
                onPointerDownOutside={() => setShowModal(false)}
                onOpenAutoFocus={() => onOpenAutoFocus()}
                onKeyDown={(e: any) => {
                    if (e.code === 'Escape') {
                        e.preventDefault()
                        setShowModal(false)
                    }
                }}
                variant={variant}
                width={width}
                height={height}
            >
                <ModalContent variant={variant}>{children}</ModalContent>
                <DialogClose asChild style={{ padding: 0, cursor: 'pointer' }}>
                    <ModalIconButton
                        aria-label="Close"
                        data-testid={'close-button'}
                    >
                        <FontAwesomeIcon
                            data-testid={'close-icon'}
                            id={'close'}
                            icon={faXmark}
                            onClick={() => setShowModal(false)}
                            onKeyDown={(e) =>
                                handleEnterPressEvent(e, () =>
                                    setShowModal(false)
                                )
                            }
                            tabIndex={0}
                        />
                    </ModalIconButton>
                </DialogClose>
            </ModalComponent>
        </Dialog>
    )
}

export default DefaultModal
