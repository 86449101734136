import { styled } from '@/src/stitches.config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping } from '@fortawesome/pro-light-svg-icons'
import {
    NavigationMenuItem,
    NavigationMenuList,
    NavigationMenuRoot,
} from '@/src/common/components/elements/Navigation/StyledComponents'
import { LabelMenu } from '@/src/common/components/elements/Menus/StyledComponents'

const CartIcon = styled(FontAwesomeIcon, {
    color: '$primary',
    fontSize: 15,
    ml: 0,
    mr: 8,
    '[data-state=open] &': {
        color: '$primary',
    },
    '@media (prefers-reduced-motion: no-preference)': {
        transition: 'transform 250ms ease',
    },
    '@media (max-width:350px)': {
        display: 'none !important',
    },
})

const StyledText = styled('span', {
    textAlign: 'left',
    textTransform: 'uppercase',
})

interface HeaderCartButtonProps {
    openCartFlyout: Function
    itemCount?: number | null
}

const HeaderCartButton = ({
    openCartFlyout,
    itemCount,
}: HeaderCartButtonProps) => {
    const itemCountText = itemCount ? `(${itemCount})` : ''

    return (
        <NavigationMenuRoot aria-label={'Cart'}>
            <NavigationMenuList>
                <NavigationMenuItem
                    onPointerMove={(event) => event.preventDefault()}
                    onPointerLeave={(event) => event.preventDefault()}
                    onClick={() => openCartFlyout()}
                    data-testid={'cart-menu'}
                >
                    <LabelMenu>
                        <CartIcon icon={faCartShopping} />
                        <StyledText>Cart {itemCountText}</StyledText>
                    </LabelMenu>
                </NavigationMenuItem>
            </NavigationMenuList>
        </NavigationMenuRoot>
    )
}

export default HeaderCartButton
