import { useMutation } from 'react-query'
import postAddCart from '../queries/PostAddCartQuery'
import {
    PG_CART_SESSION_UUID,
    PG_CART_STATE_UUID,
    WL_CART_SESSION_UUID,
    WL_CART_STATE_UUID,
} from '@/config/cookieNames'
import { useCookies } from 'react-cookie'
import { PARTNER_DATA_COOKIE, PARTNER_SLUG } from '@/src/common/constants'

const usePostAddCart = () => {
    const [cookies, setCookie] = useCookies([
        PG_CART_SESSION_UUID,
        PARTNER_DATA_COOKIE,
        PARTNER_SLUG,
    ])
    const hasPartnerData = Boolean(
        cookies[PARTNER_DATA_COOKIE] || cookies[PARTNER_SLUG]
    )

    const { mutate, data, error, isLoading, isSuccess, isError } = useMutation(
        'postAddCart',
        postAddCart,
        {
            onSuccess: (cartResponse: {
                cart_session_uuid: string
                cart_state_uuid: string
            }) => {
                setCookie(
                    hasPartnerData
                        ? WL_CART_SESSION_UUID
                        : PG_CART_SESSION_UUID,
                    cartResponse.cart_session_uuid,
                    { path: '/', domain: window.location.hostname }
                )
                setCookie(
                    hasPartnerData ? WL_CART_STATE_UUID : PG_CART_STATE_UUID,
                    cartResponse.cart_state_uuid,
                    {
                        path: '/',
                        domain: window.location.hostname,
                    }
                )
            },
            onError: () => {},
            // @ts-ignore
            refetchOnWindowFocus: false,
            retry: false,
        }
    )

    return { mutate, data, error, isLoading, isSuccess, isError }
}

export default usePostAddCart
