import { FC } from 'react'
import { styled } from '@/src/stitches.config'
import Image from 'next/image'
import NextLink from 'next/link'

const HeaderWrapper = styled('header', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: '.5rem',
    borderBottom: '2px solid black',
    paddingBottom: '1rem',
    '@md': {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        borderBottom: 'none',
        paddingBottom: '0',
    },
})

const NavigationMenuItemDivider = styled('div', {
    display: 'none',
    my: '6px',
    mx: '61px',
    borderLeft: '1px solid $darkest',
    height: '23px',
    '@md': {
        display: 'block',
    },
})

const StyledPartnerLogo = styled(NextLink, {
    position: 'relative',
    width: '300px',
    maxWidth: '100%',
    height: '60px',
    '@md': {
        height: '128px',
    },
})

export interface IPrivateLabelPartnerHeader {
    partnerLogo: string
}

const PrivateLabelPartnerHeader: FC<IPrivateLabelPartnerHeader> = ({
    partnerLogo,
}) => {
    return (
        <>
            <HeaderWrapper>
                <StyledPartnerLogo href={`/`} passHref>
                    <Image
                        src={partnerLogo}
                        alt={`Logo`}
                        fill
                        sizes="100vw"
                        style={{
                            objectFit: 'contain',
                        }}
                    />
                </StyledPartnerLogo>
                <NavigationMenuItemDivider />
                <NextLink href={'/activate'}>Activate card</NextLink>
                <NavigationMenuItemDivider />
                <NextLink href={'/check-balance'}>Check balance</NextLink>
            </HeaderWrapper>
        </>
    )
}

export default PrivateLabelPartnerHeader
