export function formatFullDateSlashed(date: Date): string {
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
}

export function formatExpirationDateSlashed(date: Date): string {
    return `${String(date.getMonth() + 1).padStart(
        2,
        '0'
    )}/${date.getFullYear()}`
}

export function formatDayMonthYear(date: Date): string {
    const fullYear = date.getFullYear()
    const paddedDay = String(date.getDate()).padStart(2, '0')
    const month = date.toLocaleString('default', { month: 'long' })

    return `${paddedDay} ${month} ${fullYear} ${format12HourTimeWithZone(date)}`
}
export function formatYMDDateDashed(date: Date): string {
    const fullYear = date.getFullYear()
    const paddedMonth = String(date.getMonth() + 1).padStart(2, '0')
    const paddedDay = String(date.getDate()).padStart(2, '0')

    return `${fullYear}-${paddedMonth}-${paddedDay}`
}

export function format12HourTimeWithZone(date: Date): string {
    const formatter = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
        timeZone: 'America/New_York',
        timeZoneName: 'short',
    })
    return formatter.format(date)
}

export function formatFullDateWithMonthSpelled(date: Date) {
    return date.toLocaleDateString('en-us', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    })
}

export function formatDateWithoutMonthSpelled(date?: Date) {
    if (!date) {
        return ''
    }

    return date.toLocaleDateString('en-us', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
    })
}

export function formatDate2DigitMonthDayYear(date: Date) {
    return new Intl.DateTimeFormat('en-US', {
        day: 'numeric',
        month: 'numeric',
        year: '2-digit',
    }).format(date)
}
export function formattedExpDate() {
    let currentDate = new Date()
    let expirationDate = new Date(currentDate)
    expirationDate.setFullYear(currentDate.getFullYear() + 7)
    return expirationDate.toString().slice(0, 15)
}
