import { styled } from '@/src/stitches.config'

export const GiftBox = styled('div', {
    position: 'relative',
    pb: 20,
    display: 'flex',
    jc: 'center',
    maxWidth: 500,
    '.gift-box-image': {
        width: '100%',
        maxWidth: 400,
        height: 'auto',
    },
    '.card-image': {
        position: 'absolute',
        bottom: '6.5%',
        left: '25%',
    },
})
