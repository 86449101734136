import { styled } from '@/src/stitches.config'

export const HeaderWrapper = styled('header', {
    backgroundColor: '$white',
    padding: 0,
    color: '$dark',
    fontSize: '3',
    position: 'relative',
    variants: {
        variant: {
            flex: {
                display: 'flex',
                jc: 'center',
                zIndex: 2,
                boxShadow: 'grey 0px 1px 15px -4px',
            },
        },
    },
})

export const HeaderTop = styled('div', {
    display: 'flex',
    width: '$default',
    minHeight: 60,
    fd: 'row',
    margin: 'auto',
    maxWidth: '95%',
    justifyContent: 'space-between',
    '@md': {
        height: 95,
    },
})

export const HeaderTopContentLeft = styled('div', {
    display: 'flex',
    ai: 'center',
    mr: 'auto',
    flex: 0.75,
})
export const HeaderTopContentRight = styled('div', {
    display: 'flex',
    jc: 'right',
    ai: 'center',
    gap: 10,
    flex: 1,
    '& button': {
        '& span': {
            ml: '0 !important',
        },
    },
})

export const HeaderBottom = styled('div', {
    display: 'none',
    '@md': {
        display: 'flex',
        position: 'relative',
        width: '100%',
    },
})

export const HideContentMdDown = styled('div', {
    display: 'none',
    '@md': {
        display: 'flex',
    },
})
