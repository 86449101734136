// both setMetaFields and setSensitiveMetaFields are available for setting info in chat
// pass in fields as object. example: {name: name, email: email}

export function setAdaChatMetaField(fields: object) {
    try {
        window.adaEmbed.setMetaFields(fields)
    } catch {}
}

export function setSensitiveAdaChatMetaField(fields: object) {
    try {
        window.adaEmbed.setSensitiveMetaFields(fields)
    } catch {}
}
