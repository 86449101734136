import MobileNavDrawer from '@/src/common/components/elements/Layout/Navigation/Mobile/MobileNavDrawer'
import LogoLink from '@/src/common/components/elements/Layout/LogoLink'
import { HeaderTopContentLeft } from '@/src/common/components/elements/Navigation/header/StyledComponents'
import { TPartnerPayload } from '@/src/modules/partners/types'

type TLogoNavProps = {
    isCorporate: boolean
    partnerData?: TPartnerPayload
    showMobileNav?: boolean
    imageMinWidth?: string | number
}

const HeaderLogoAndMobileNav = ({
    partnerData,
    isCorporate,
    showMobileNav,
}: TLogoNavProps) => {
    return (
        <HeaderTopContentLeft>
            {showMobileNav && (
                <MobileNavDrawer
                    partner={partnerData?.partnerName ?? ''}
                    isCorporate={isCorporate}
                />
            )}
            <LogoLink isCorporate={isCorporate} partnerData={partnerData} />
        </HeaderTopContentLeft>
    )
}

export default HeaderLogoAndMobileNav
