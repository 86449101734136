import { styled, theme } from '@/src/stitches.config'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const LabelMenu = styled(NavigationMenu.Trigger, {
    fontFamily: theme.fonts.default,
    p: '12px 10px 12px 12px',
    border: 'none',
    fontSize: '16px',
    color: '$darkest',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    background: 'transparent',
    '&[data-state="open"]': {
        background: '$white',
        textDecoration: 'underline',
        textDecorationColor: '$primary',
        textDecorationThickness: '2px',
        textUnderlineOffset: '8px',
    },
})

export const ChevronIcon = styled(FontAwesomeIcon, {
    color: '$primary',
    width: '12px',
    height: '12px',
    length: 0,
    marginLeft: '.5rem',
    '[data-state=open] &': {
        color: '$primary',
    },
    '@media (prefers-reduced-motion: no-preference)': {
        transition: 'transform 250ms ease',
    },
})
