function convertDecimalToNumber(
    number: number | undefined,
    limitToTwoDecimals: boolean
): { number: number; multipliedBy100: number } {
    if (!number) {
        return { number: 0, multipliedBy100: 0 }
    }

    // do not use the incentiveTimes100 for points/miles
    // that is only used for representing percentages as whole numbers
    return limitToTwoDecimals
        ? {
              number: Number(number.toFixed(2)),
              multipliedBy100: Number((number * 100).toFixed(2)),
          }
        : { number: number, multipliedBy100: number * 100 }
}

export default convertDecimalToNumber
