import { ICardToCardProduct, IVisaProduct } from '@/src/common/interfaces/gtag'

interface IProductDetails {
    array: (ICardToCardProduct | IVisaProduct)[]
    brandName: string
    designTypeOrName: string
    denomination: string
}

interface IBrandedProductDetails extends IProductDetails {
    item: any
}

export function pushUnbrandedPGPlusProductToArray({
    array,
    brandName,
    designTypeOrName,
    denomination,
}: IProductDetails) {
    array.push({
        item_id: `${brandName} virtual`,
        item_name: `${brandName} virtual`,
        item_brand: brandName,
        item_category: 'virtual',
        item_variant: designTypeOrName,
        price: parseFloat(denomination),
        quantity: 1,
    })
}

export function pushBrandedPGPlusProductToArray({
    array,
    brandName,
    designTypeOrName,
    denomination,
    item,
}: IBrandedProductDetails) {
    const brandCategories =
        item?.customization_data?.selected_gyp_brand?.brand_categories ?? []

    array.push({
        item_id: `${brandName} virtual`,
        item_name: `${brandName} virtual`,
        item_brand: brandName,
        item_variant: designTypeOrName,
        item_category: 'virtual',
        item_category2: brandCategories[0] ?? null,
        item_category3: brandCategories[1] ?? null,
        item_category4: brandCategories[2] ?? null,
        item_category5: brandCategories[3] ?? null,
        price: parseFloat(denomination),
        quantity: 1,
    })
}

interface IVisaProductDetails {
    array: (ICardToCardProduct | IVisaProduct)[]
    brandName: string
    mediumType: string
    denomination: string
    designTypeOrName: string
    isBox?: boolean
    quantity?: number
}

export function pushVisaProductToArray({
    array,
    brandName,
    mediumType,
    denomination,
    designTypeOrName,
    isBox = false,
    quantity = 1,
}: IVisaProductDetails) {
    array.push({
        id: `${brandName} ${mediumType}`,
        name: `${brandName} ${isBox ? 'boxset' : mediumType}`,
        category: mediumType,
        price: parseFloat(denomination),
        brand: brandName,
        quantity: quantity,
        item_variant: designTypeOrName,
    })
}
