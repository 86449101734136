import * as React from 'react'
import 'react-modern-drawer/dist/index.css'
import isEmpty from 'lodash/isEmpty'

export interface IPromoBanner {
    promoBannerHtml: string
    isCorporate: boolean
}

const CorpPromoBanner: React.FC<IPromoBanner> = ({ promoBannerHtml }) => {
    return (
        <>
            {!isEmpty(promoBannerHtml) && (
                <div
                    dangerouslySetInnerHTML={{
                        __html: promoBannerHtml,
                    }}
                />
            )}
        </>
    )
}

export default CorpPromoBanner
