import {
    DEFAULT_BUY_PAGE,
    PARTNER_BRAND_PAGE,
    PARTNER_PATH,
} from '@/src/modules/pg-plus/constants'
import { TPartnerPayload } from '@/src/modules/partners/types'

function determineBuyPage(
    defaultPath: string,
    partnerData?: TPartnerPayload
): string {
    if (!partnerData) {
        return defaultPath ?? DEFAULT_BUY_PAGE
    }

    if (partnerData?.store_page_brand_slug) {
        return `${PARTNER_PATH}/${partnerData.store_page_brand_slug}`
    }

    return PARTNER_BRAND_PAGE
}

export default determineBuyPage
