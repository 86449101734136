import { styled, theme } from '@/src/stitches.config'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight'
import { faPhotoFilm } from '@fortawesome/free-solid-svg-icons/faPhotoFilm'
import { faArrowUpToLine } from '@fortawesome/pro-light-svg-icons/faArrowUpToLine'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ClipLoader from 'react-spinners/ClipLoader'
import { ButtonHTMLAttributes, ReactNode } from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import {
    Arrow,
    ButtonContentSpacer,
    ContentWrapper,
} from '@/src/common/components/marketing/buttons/ButtonStyledComponents'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    label: string | JSX.Element
    variant?:
        | 'primary'
        | 'secondary'
        | 'primaryOutline'
        | 'secondaryOutline'
        | 'deactivated'
        | 'graySquareButton'
        | 'graySquareButtonActive'
        | 'graySquareButtonWithGradient'
        | 'graySquareButtonVertical'
        | 'graySquareButtonVerticalActive'
        | 'whiteOutlineTransparent'
    size?: 'sm' | 'md' | 'lg'
    width?: 'standard' | 'fitContent' | 'full' | 'wide'
    textTransform?: 'uppercase' | 'lowercase' | 'none'
    onClick?: () => void
    testId?: string
    css?: object
    builderBlock?: any
    builderState?: any
    showArrow?: boolean
    iconType?: 'upload' | 'image'
    isLoading?: boolean
    'data-testid'?: string
}

const sharedButtonStyles = {
    color: '$white',
    transition: 'background-color 150ms ease',
    border: '2px solid transparent',
}

const graySquareButtonStyles = {
    color: '$primary',
    backgroundColor: '$white',
    border: '2px solid $mediumGray',
    transition: 'background-color 150ms ease',
    mb: '20px',
    br: '10px',
    mr: '10px',
}

export const StyledButton = styled('button', {
    display: 'inline-flex',
    ai: 'center',
    jc: 'center',
    width: 'fit-content',
    maxWidth: '100%',
    height: 'min-content',
    fontWeight: 500,
    fontFamily: theme.fonts.default,
    letterSpacing: '0.05em',
    cursor: 'pointer',
    lineHeight: '1.25',
    borderRadius: '25px',
    '&:disabled': {
        background: '$mediumGray',
        color: '$white',
        borderColor: '$mediumGray',
        cursor: 'not-allowed',
        '&:hover, &:focus': {
            background: '$light',
            color: '$mediumGray',
        },
    },
    variants: {
        variant: {
            primary: {
                backgroundColor: '$primary',
                ...sharedButtonStyles,
                '&:not(:disabled):hover, &:not(:disabled):focus': {
                    borderColor: '$primary',
                    backgroundColor: '$primaryLight',
                    color: '$primary',
                },
            },
            primaryOutline: {
                color: '$primary',
                backgroundColor: '$white',
                transition: 'background-color 150ms ease',
                border: '2px solid $primary',
                '&:not(:disabled):hover, &:not(:disabled):focus': {
                    borderColor: '$primary',
                    backgroundColor: '$primaryLight',
                },
            },
            secondary: {
                backgroundColor: '$secondary',
                ...sharedButtonStyles,
                '&:not(:disabled):hover, &:not(:disabled):focus': {
                    borderColor: '$secondary',
                    backgroundColor: '$secondaryLight',
                    color: '$secondary',
                },
            },
            secondaryOutline: {
                color: '$secondary',
                backgroundColor: '$white',
                transition: 'background-color 150ms ease',
                border: '2px solid $secondary',
                '&:not(:disabled):hover, &:not(:disabled):focus': {
                    borderColor: '$secondary',
                    backgroundColor: '$secondaryLight',
                },
            },
            whiteOutlineTransparent: {
                color: '$white',
                backgroundColor: '$transparent',
                transition: 'background-color 150ms ease',
                border: '3px solid $white',
                '&:not(:disabled):hover, &:not(:disabled):focus': {
                    borderColor: '$white',
                    backgroundColor: '$white',
                    color: '$primary',
                },
            },
            deactivated: {
                color: '$white',
                backgroundColor: '$mediumGray',
                transition: 'background-color 150ms ease',
                border: '2px solid $mediumGray',
            },
            graySquareButton: {
                ...graySquareButtonStyles,
                '&:not(:disabled):hover, &:not(:disabled):focus': {
                    borderColor: '$primary',
                    backgroundColor: '$primaryLight',
                },
            },
            graySquareButtonActive: {
                ...graySquareButtonStyles,
                backgroundColor: '$primaryLight',
                border: '2px solid $primary',
            },
            graySquareButtonWithGradient: {
                ...graySquareButtonStyles,
                '&:not(:disabled):hover, &:not(:disabled):focus': {
                    background: `linear-gradient(white, white) padding-box, linear-gradient(83.53deg, ${theme.colors.primary} 18.47%, ${theme.colors.secondary} 78%) border-box`,
                    border: '2px solid transparent',
                },
            },
            graySquareButtonVerticalActive: {
                ...graySquareButtonStyles,
                backgroundColor: '$primaryLight',
                border: '2px solid $primary',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
            },
            graySquareButtonVertical: {
                ...graySquareButtonStyles,
                '&:not(:disabled):hover, &:not(:disabled):focus': {
                    borderColor: '$primary',
                    backgroundColor: '$primaryLight',
                },
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
            },
        },
        size: {
            sm: {
                px: '$4',
                py: '$2',
                fontSize: '$3',
            },
            md: {
                px: '$3',
                py: '$2',
                fontSize: '$5',
            },
            lg: {
                px: '$4',
                py: '$2',
                fontSize: '$7',
            },
        },
        width: {
            standard: {
                width: '275px',
                '@sm': {
                    width: 'auto',
                    minWidth: '275px',
                },
            },
            fitContent: {
                width: 'max-content',
            },
            full: {
                width: '100%',
            },
            wide: {
                width: 375,
            },
        },
        textTransform: {
            uppercase: {
                textTransform: 'uppercase',
            },
            lowercase: {
                textTransform: 'lowercase',
            },
            none: {
                textTransform: 'unset',
            },
        },
    },
    defaultVariants: {
        variant: 'primary',
        size: 'md',
        width: 'fitContent',
    },
    '.adjust-icon': {
        marginLeft: '0.5rem',
    },
})

const LoaderWrapper = styled('span', {
    marginLeft: 'auto',
    width: '10%',
    display: 'inline-flex',
    transform: 'translateY(-1px)',
})

function determineButtonContent(
    showArrow: boolean,
    isLoading: boolean,
    label: string | JSX.Element,
    children: ReactNode | undefined
) {
    if (isLoading) {
        return (
            <>
                <ButtonContentSpacer />
                {label}
                <LoaderWrapper>
                    <ClipLoader
                        color={'inherit'}
                        loading={isLoading}
                        size={20}
                    />
                </LoaderWrapper>
            </>
        )
    }

    if (showArrow) {
        return (
            <>
                <ButtonContentSpacer />
                {label}
                <Arrow>
                    <FontAwesomeIcon icon={faArrowRight} />
                </Arrow>
            </>
        )
    }

    return (
        <>
            {label}
            {children}
        </>
    )
}

function determineIconName(iconType: string | undefined) {
    if (!iconType) {
        return undefined
    }

    switch (iconType) {
        case 'upload': {
            return faArrowUpToLine
        }
        case 'image':
            return faPhotoFilm
        default:
            return undefined
    }
}

export const Button = ({
    variant,
    size = 'md',
    textTransform,
    label,
    showArrow = false,
    iconType,
    builderState,
    builderBlock,
    isLoading = false,
    ...props
}: ButtonProps) => {
    const iconName: IconDefinition | undefined = determineIconName(iconType)
    const { css: cssProp, ...propsWithoutCss } = props

    return (
        // @ts-ignore
        <StyledButton
            variant={variant}
            size={size}
            textTransform={textTransform}
            style={cssProp}
            data-testid={props['data-testid'] ?? 'standard-button'}
            {...propsWithoutCss}
        >
            {iconName && (
                <FontAwesomeIcon icon={iconName} className={'adjust-icon'} />
            )}
            <ContentWrapper>
                {determineButtonContent(
                    showArrow,
                    isLoading,
                    label,
                    props?.children
                )}
            </ContentWrapper>
        </StyledButton>
    )
}

export default Button
