import { LineItemText } from '@/src/modules/cart/StyledComponents'
import { TShippingDetails } from '@/src/modules/checkout/utilities/types'

interface IShipping {
    shipping: TShippingDetails
    detailsExpanded: boolean
}

const ShippingDetails = ({ shipping, detailsExpanded }: IShipping) => {
    const { address, carrier: shippingOption } = shipping
    const {
        name = '',
        city = '',
        state = '',
        zip = '',
        address1 = '',
        address2 = '',
    } = address ?? {}
    const cityState = `${city}, ${state?.toUpperCase()} ${zip}`

    return (
        <LineItemText fontColor={'dark'} fontSize={'smaller'}>
            To: <span className={'title-case bold'}>{name}</span>
            {detailsExpanded && (
                <>
                    <br />
                    Shipping:{' '}
                    <span className={'title-case bold'}>{address1}</span>
                    {address2 && (
                        <>
                            <br />
                            <span className={'title-case bold'}>
                                {address2}
                            </span>
                        </>
                    )}
                    <br />
                    <span className={'title-case bold'}>{cityState}</span>
                    <br />
                    <span className={'title-case'}>{shippingOption}</span>
                </>
            )}
        </LineItemText>
    )
}

export default ShippingDetails
