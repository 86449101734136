import { styled, theme } from '@/src/stitches.config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const NavigationWrapper = styled('div', {
    py: 10,
    '.blue-underline': {
        color: '$secondary !important',
        textDecoration: 'underline',
    },
    '.gradient-words': {
        background: `-webkit-linear-gradient(83.53deg, ${theme.colors.primary} 18.47%, ${theme.colors.secondary} 78%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
})

export const CloseButton = styled(FontAwesomeIcon, {
    ml: 15,
    mb: 5,
    fontSize: '1.75rem',
})

export const BaseNavItem = styled('div', {
    overflowY: 'auto',
    '& p': {
        width: '90%',
        margin: '12px auto',
    },
})

export const NavHeader = styled('p', {
    fontSize: '.93rem',
    my: 10,
})

export const SubSection = styled('div', {
    py: 5,
    backgroundColor: '$lightest',
})

export const SubSectionWrapper = styled('div', {})

export const SectionLabel = styled('p', {
    color: '#828993',
    fontSize: '.75rem',
    letterSpacing: 0.5,
    textTransform: 'uppercase',
})

export const NavDropDownIcon = styled('span', {
    pointer: 'cursor',
    '& svg': {
        fontSize: '.85rem',
        color: '$primary',
        ml: 10,
    },
})

export const SubItemFAIcon = styled(FontAwesomeIcon, {
    fontSize: '1rem',
    width: 20,
    mr: 10,
    color: '$secondary',
})

export const ButtonWrapper = styled('div', {
    width: '90%',
    display: 'flex',
    fd: 'column',
    gap: 10,
    pt: 20,
    margin: 'auto',
})

export const ImagesWrapper = styled('div', {
    display: 'flex',
    jc: 'center',
    width: '100%',
    my: 30,
    gap: 10,
    '& img': {
        width: '35%',
        height: 'auto',
    },
})
