import { useQuery } from 'react-query'
import getFirebaseToken from '@/src/common/queries/getFirebaseToken'

const useGetFirebaseToken = () => {
    const { refetch, data, error } = useQuery(
        'getFirebaseToken',
        getFirebaseToken,
        {
            // @ts-ignore
            refetchOnWindowFocus: false,
        }
    )

    return { refetch, data, error }
}

export default useGetFirebaseToken
