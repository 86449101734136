import { styled } from '@/src/stitches.config'

const StyledSpan = styled('span', {
    ml: 2,
    color: '$error',
})

const RequiredAsterisk = () => {
    return <StyledSpan>&#42;</StyledSpan>
}

export default RequiredAsterisk
